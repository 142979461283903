// import logo from './logo.svg';
import './App.css';
import Home from './page/Home';
import configureStore from './store/Configurstore'
import CommonComponent from './page/Home';
import Search from './page/Search';
import ContentDetails from './page/ContentDetails';
import NewsDetails from './page/NewsDetails';

import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";

import {Provider} from 'react-redux'
import React, { Component,Fragment } from 'react';

import {getRoutes,getFooterSection,getTopMenu,getRightSiteMenu} from "./actions/Utils"
import {base_path} from './config/Api'

const store = configureStore();


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utils:store.getState().utils,
      routemenu:store.getState().utils.routemenu,
    };

    
  }

  componentDidMount = () => {
   
    store.dispatch(getRoutes()).then((res) => {
      // console.log(" route data = ",res);
      this.setState({routemenu:res})
    });

    store.dispatch(getTopMenu()).then((res) => {
      // console.log('Header data = ',res);
    });
    store.dispatch(getRightSiteMenu()).then((res) => {
      // console.log('Header data = ',res);
    });
    // store.dispatch(getHeaderSection()).then((res) => {
    //   // console.log('Header data = ',res);
    // });

    store.dispatch(getFooterSection()).then((res) => {
      // console.log('Footer data = ',res);
    });
    // store.dispatch(getSetting()).then((res) => {
    //   // console.log('Footer data = ',res);
    // });

  
  
  }

  render() {
    // console.log('app routemenu js file = ',this.state.routemenu);
    const routemenu = this.state.routemenu;

    // var site = base_path.split("/");
    // let pathName = this.state.utils.path;
    var  basename = "/";
    if(base_path.includes("mircloudbd.com") || base_path.includes("3000") ) {
         basename = "/";
    }
    else if(base_path.includes("sites")){
         basename = "mir-admin/sites/";
    }
    else {
          basename = "/";
    }
    

    return (
      <Provider store={store}>
        <Router  basename={basename}>
            <Switch>
                <Route path="/" exact='/'> <Home /> </Route>
                <Route  path="/search" render={() => <Search />} />
                <Route  path="/content/details" render={() => <ContentDetails />} />
                <Route  path="/news/:slug" render={() => <NewsDetails />} />
                <Route  path="/event/:slug" render={() => <NewsDetails />} />
                   {/* {routemenu.map(menu => <Route key={menu.id} exact path={'/'+menu.slug} render={() => <CommonComponent />}/>)} */}
                   {routemenu.map((menu, index,{length}) => {
                  if(index + 1 === length) { 
                    return <Fragment key={index}>
                      <Route key={menu.id} exact path={menu.slug} render={() => <CommonComponent />}/>
                      {/* <Route path="*" component={() => "404 NOT FOUND"} /> */}
                    </Fragment>
                  }
                  else {
                    return <Route key={menu.id} exact path={menu.slug} render={() => <CommonComponent />}/>
                  }
                 
                })}

               
                
            </Switch>
        </Router>
       </Provider>
    );
  }
}

export default App;

