import React, { Fragment } from 'react';
// import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,getaQuote} from '../../actions/Utils'
import { connect } from 'react-redux';
import qs from 'qs';
import axiosInstance,{version} from '../../config/Api'
import Recaptcha from 'react-recaptcha';
// import {google_recaptcha_site_key} from '../../config/Api'


class Getquoteform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator:{
                name:'',
                email:'',
                phone:'',
                // company_name:'',
                vcpu:'',
                ram:'',
                storage:'',
                g_recaptcha:'',
            },
            recaptcha_key:'',
            message:'',
            message_type:'',
            gkey:0,
            showClassName:''
        };
        this.key = 0;
        this.refs = React.createRef();
        this.handleVerify = this.handleVerify.bind(this)
        this.handleShowgetaquote = this.handleShowgetaquote.bind(this)
        this.handleHidegetaquote = this.handleHidegetaquote.bind(this)
        const recaptchaRef = React.createRef();
    }

    handleMessageClose = () => {
        this.setState({message:'',message_type:''})
    }

    handleSubmit = () => {
        
       const gkey = this.state.gkey;
        const userFormData = {
            name:this.name.value,
            email:this.email.value,
            phone:this.phone.value,
            // company_name:this.company_name.value,
            vcpu:this.vcpu.value,
            ram:this.ram.value,
            storage:this.storage.value,
            g_recaptcha:this.state.validator.g_recaptcha,
        }
       
 
        const resetValidator = {
            name:'',
            email:'',
            phone:'',
            // company_name:'',
            vcpu:'',
            ram:'',
            storage:'',
            g_recaptcha:this.state.validator.g_recaptcha,
        }
        var validation = {};
       
        if(userFormData.name == '') {
            validation.name = 'The name field is required.';
        }
        
        if(userFormData.email == '') {
            validation.email = 'The email field is required.';
        }

        if(userFormData.phone == '') {
            validation.phone = 'The phone field is required.';
        }
        // if(userFormData.company_name == '') {
        //     validation.company_name = 'The company name field is required.';
        // }
        if(userFormData.vcpu == '') {
            validation.vcpu = 'The VCPU field is required.';
        }
        if(userFormData.ram == '') {
            validation.ram = 'The  RAM is required.';
        }
        if(userFormData.storage == '') {
            validation.storage = 'The storage field is required.';
        }
        if(userFormData.g_recaptcha == '') {
            validation.g_recaptcha = 'Please ensure that you are a human!';
        }
       
        if(Object.keys(validation).length) {
            this.setState({validator:{...resetValidator,...validation},message:''});
        }
        else {
           
            userFormData.g_recaptcha = this.state.recaptcha_key;

            axiosInstance.post(version+'sendQuotation',qs.stringify(userFormData)).then((res) => {

               
                this.name.value = '';
                this.email.value = '';
                this.phone.value = '';
                // this.company_name.value = '';
                this.vcpu.value = '';
                this.ram.value = '';
                this.storage.value = '';

                resetValidator.g_recaptcha = false;

                this.setState({message:res.data.success,message_type:'success',validator:{...resetValidator},gkey:gkey === 0?1:0,recaptcha_key:'',})
            })
            .catch(error => {
               
             
                if(typeof error.response.data.errors != "undefined") {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                       validation[key] = value;
                    }
                    this.setState({validator:validation})
                }
                else if(error.response) {
                   var html = '';
                    if(Object.keys(error.response.data).length) {

                        for (const [key, value] of Object.entries(error.response.data)) {
                           
                            html += `${value}`;
                        }
                        
                    }
                  
                } 
                if(html){
                    this.setState({message:html,message_type:'danger',validator:{...resetValidator}})
                }
                
            })
        }
        
       
    }
    
    recaptchaOnloaded = () => {
        //console.log("recaptcha onloaded")
    }
    handleVerify = (response) => {

        if(response) {
            this.setState({recaptcha_key:response,validator:{...this.state.validation, g_recaptcha:true}})
        }
        // else {
        //     this.setState({g_recaptcha:false})
        // }
       
    }
    handleShowgetaquote = () => {

        this.setState({showClassName:'show-getaquote'})
       
    }
    handleHidegetaquote = () => {

        this.setState({showClassName:''})
       
    }
   

  render() {
      
 
    const {validator,gkey,showClassName} = this.state;
    const recaptcha_site_key = Object.keys(this.props.setting)?this.props.setting.google_recaptcha_site_key:'';
   
    return (
        <Fragment>
            <div className="get-a-quote">
                <div className="container">
                    <div className="quote-button">
                        <div onClick={this.handleShowgetaquote} className="btn">
                            <img src="/img/quote.svg"/></div>
                            <div id="popup1" className={`popup ${showClassName}`}>
                                <div className="popup-header">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="popup-header-logo">
                                                <img src="img/logo.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <h3>Hi There </h3>																							
                                            <p>Please fill in the form below before click on Submit button</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="popup-product-info">

                                <div className="row">
                                {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12">
                                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                        {this.state.message}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>}
                                {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                                    <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                        {this.state.message}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>vCPU	<span>*</span>
                                        </label>
                                        
                                        <input type="text" ref={(input) => this.vcpu = input} placeholder="4" className={validator.vcpu?'form-control input-error':'form-control'} name=""/>
                                       
                                        {/* {validator.vcpu && <span className="help-block form-text text-danger">{validator.vcpu}</span>} */}
                                    </div>
                                    <div className="col-md-4">
                                        <label>RAM	<span>*</span>
                                        </label>
                                        <input type="text" ref={(input) => this.ram = input} placeholder="8 GB" className={validator.ram?'form-control input-error':'form-control'} name=""/>

                                        {/* {validator.ram && <span className="help-block form-text text-danger">{validator.ram}</span>} */}

                                    </div>
                                    <div className="col-md-4">
                                        <label>Storage	<span>*</span>
                                        </label>
                                        <input type="text" ref={(input) => this.storage = input} placeholder="200 GB" className={validator.storage?'form-control input-error':'form-control'} name=""/>

                                        {/* {validator.storage && <span className="help-block form-text text-danger">{validator.storage}</span>} */}

                                    </div>
                                </div>
                                <div className="popup-form">
                                    <form action="#">
                                        <input type="text" className={validator.name?'form-control input-error':'form-control'} ref={(input) => this.name = input} placeholder="Name*" name=""/>
                                        {/* {validator.name && <span className="help-block form-text text-danger">{validator.name}</span>} */}

                                        <input type="text" className={validator.phone?'form-control input-error':'form-control'} ref={(input) => this.phone = input} placeholder="Contact Number*" name=""/>
                                        {/* {validator.phone && <span className="help-block form-text text-danger">{validator.phone}</span>} */}

                                        <input type="text" className={validator.email?'form-control input-error':'form-control'} ref={(input) => this.email = input} placeholder="Email*" name=""/>
                                        {/* {validator.email && <span className="help-block form-text text-danger">{validator.email}</span>} */}


                                        {/* <input type="text" className={validator.company_name?'form-control input-error':'form-control'} ref={(input) => this.company_name = input} placeholder="Company Name*" name=""/> */}
                                        {/* {validator.company_name && <span className="help-block form-text text-danger">{validator.company_name}</span>} */}
                                        
                                       

                                        {/* <GoogleReCaptchaProvider reCaptchaKey="6LfcpZcaAAAAAJJJZfrgbPb7_h95P-XVwAQNQLA9">
                                                <GoogleReCaptcha onVerify={()=>this.handleVerify()} />
                                        </GoogleReCaptchaProvider> */}
                                        <div className="getquote_recaptcha">
                                            {recaptcha_site_key && <Recaptcha
                                                sitekey={recaptcha_site_key}
                                                render="explicit"
                                                verifyCallback={this.handleVerify}
                                                onloadCallback={()=>this.recaptchaOnloaded()}
                                                elementID = 'g-recaptcha'
                                                ref={e => (this.recaptchaInstance = e)}
                                                key={gkey}
                                            />}
                                            {validator.g_recaptcha && <span className="help-block form-text text-danger">{validator.g_recaptcha}</span>}
                                        </div>

                                        <button type="button" className="btn-style-three" onClick={(e)=>this.handleSubmit(e)}>Submit</button>
                                    </form>
                                </div>
                            </div>	<div onClick={this.handleHidegetaquote} className="close">&times;</div>
                        </div>
                        <div onClick={this.handleHidegetaquote} className="close-popup"></div>
                    </div>
		    </div>
	        </div>
        </Fragment>
    );
  }
}

const mapStateToPropos = state => ({
    setting:state.utils.setting
})



const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    getaQuote : (requestData)=>{dispatch(getaQuote(requestData))},
})

export default connect(mapStateToPropos,mapDispatchToProps)(Getquoteform);