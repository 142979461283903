import '../App.css'
import React, { Component,Fragment, PureComponent } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer'
import { withRouter } from "react-router-dom";
import {getSetting,setPathLayout,setPath,getContentDetails} from "../actions/Utils"
import InnerHTML  from 'dangerously-set-inner-html'
import {connect} from 'react-redux' 
import {image_url} from '../config/Api'



class ContentDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            

        };
        this.callapi = 0;
    }
 
    

    componentDidMount = () => {
        const {location} = this.props.history;
        var {pathname} = location
        let str = pathname.substr(0,1);
        pathname = pathname.substring(1);
        this.setPathname(pathname);
        if(!Object.keys(this.props.utils.setting).length) {
            this.getSetting();
        }
        if(!Object.keys(this.props.content_details).length) {
            this.getdetailsContent();
        }
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        
    }

    getdetailsContent = async () => {
        const {location} = this.props.history;
        const search = location.search.substring(1);
        let data = await this.props.getContentDetails({q:search}).then((res)=>{})
        
    }

    componentDidUpdate(prevProps){

        if(this.props.content_details.content) {
            if ((prevProps.location.pathname != this.props.content_details.content.slug) && this.callapi ==0) {
                this.callapi = this.callapi +1;
                this.getdetailsContent(); 
            }
        }
        
      }


    setPathLayout = (parms) => {
       this.props.setPathLayout(parms);
    }

    setPathname = (parms) => {
       this.props.setPath(parms);
    }

    getSetting = async () => {
       let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
            return {
                path: props.utils.path,
                header:props.header,
                container:props.container,
                footer:props.footer,
                layout_id:props.utils.layout_id
             };
      }


    render() {
        // const {path,load_more_show} = this.state
        const {header_loading, footer_loading} = this.props.utils;
        const {content,content_type,section_title} = this.props.content_details;
 
        return (
            <Fragment>
                {
                (header_loading && footer_loading)?
                    <Fragment>
                        <div id="loader-1">
                            <span></span><span></span><span></span><span></span><span></span>
                        </div>
                    </Fragment>:
                <Fragment>
                    <Header parent_page = {'detailspage'} />
                    <div className="feature-header">
                    
                        <h1>{section_title}</h1>
                        <img src="/img/feature/singlepage-header.png" />
                        
                    </div>
                    <div className="container">
                        <section>
                            <div className="cloud-feature-inner">
                               
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="content-description-wrap">
                                            {content_type === 'list'?<Fragment>
                                               

                                                <div className="image">
                                                    {content.image && <img src={image_url + content.image} />}
                                                </div>
                                                <p>{content.description}</p>
                                            </Fragment>:''}
                                            {content_type === 'slider' ?<Fragment>
                                                <div className="image">
                                                    {content.long_image && <img src={image_url + content.long_image} />}
                                                </div>
                                                <p> {content.short_descripton}</p>
                                            </Fragment>:''}

                                            {content_type === 'html' ?
                                            <InnerHTML html={content.description} />
                                        :''}

                                        </div>
                                    </div>
                                   
                                </div>
                                
                            </div>
                        </section>
                    </div>
                    <Footer parent_page = {'detailspage'} />
                </Fragment>
                }
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    homelayout:state.homelayout,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    content_details:state.contents.content_details,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    setPath:(requestData) =>dispatch(setPath(requestData)),
    getContentDetails:(requestData) =>dispatch(getContentDetails(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ContentDetails));