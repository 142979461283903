import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
class Testmonial extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const sliderdata = this.props.slider;
    const content = this.props.content;
    const sectionInfo = this.props.sectionInfo;

var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500
  };

    return (
        <div className="client-testimonials">
		<section>
			<div className="container">
				<div className="wrapper">
					<div className="testimonials-slider">

                        <Slider {...settings}>
                            {Array.isArray(sliderdata) && sliderdata.map((row,key)=>{
                                    return (
                                        <Fragment key={key}>
                                            
                                                
                                                    <div className="testimonials-content" key={key}>
                                                        <h6>Testimonials</h6>
                                                        <p>
                                                            {row.short_descripton}
                                                        </p>
                                                        <div className="client-info">
                                                            <img src={image_url + row.long_image} alt="Image"/>
                                                            <h5>{row.title}</h5>
                                                            <p>
                                                                {row.designation}
                                                            </p>
                                                        </div>
                                                    </div>
                                                
                                        </Fragment>
                                    )
                                })}
                        </Slider>

                        </div>
					 
						 
						
            </div>
        </div>
    </section>
</div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(Testmonial);