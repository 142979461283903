import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import PackageList from './PackageList'
class Package extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const content = this.props.content;
    const contents = this.props.content.contents;
    const listcontent = contents[0].listcontent;
    // console.log(" what we do Package = ",this.props.content);
   
    return (
        <div className="packages">
        <section>
            <div className="container">
                <div className="wrapper">
                    {content.is_title_display &&<div className="section-title-six">
                        <h1>{content.title}</h1>
                    </div>}
                    <div className="packages-list">
                        <div className="row">
                            

                        {contents.map((rows,key) => {
                            return (
                                <Fragment key={key}>
                                        {rows.list.length && <PackageList contentList = {rows.list} content = {rows}  />}
                                </Fragment>
                            )
                        })}

                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(Package);