import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import EventCarousel from '../slider/EventCarousel'
import Htmlcontent from '../Htmlcontent'
import InnerHTML  from 'dangerously-set-inner-html'
class NewsEvent extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const content = this.props.content;
    const contents = content.contents;
    const slidercontent = contents.filter(data => data.slider_id >0);
    const evnetHeaderContent = slidercontent.length ? slidercontent[0]:'';
    const htmlcontentdata = contents.filter(data => data.slider_id == null);
    return (
            <div className="news-events">
            <section>
                <div className="container">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="section-title-two">
                                    {evnetHeaderContent && evnetHeaderContent.short_description ?<Fragment>
                                       <h1>{evnetHeaderContent.short_description}</h1> 
                                        </Fragment>:''}

                                    {evnetHeaderContent && evnetHeaderContent.description ?<Fragment><p>{evnetHeaderContent.description}</p></Fragment>:''}
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="events-btn">
                                    {evnetHeaderContent.menu ?
                                    <Link 
                                        onClick={()=>this.props.setPathLayout({id:evnetHeaderContent.menu.id,path:evnetHeaderContent.menu?evnetHeaderContent.menu.slug:''})}
                                        to={evnetHeaderContent.menu?evnetHeaderContent.menu.slug:''}
                                        className="btn-style-two">
                                           
                                            Explore
                                           
                                      
                                    </Link>
                                     
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                {htmlcontentdata ? htmlcontentdata.map((rows,key)=>{
                                    return(
                                        <Fragment key={rows.id + key}>
                                            <InnerHTML html={rows.description} />
                                        </Fragment>
                                       
                                    )
                                }):''}
                            </div>
                            <div className="col-md-9">
                                <section className="regular slider">
                                {slidercontent.length ? slidercontent.map((srow,skey)=>{
                                    return(
                                        <Fragment key={srow.id + skey}>
                                            <EventCarousel content={srow.slider} image_url = {image_url}/>
                                        </Fragment>
                                    )
                                }):''}
                                   
                              </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>    

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(NewsEvent);