import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { Link } from "react-router-dom";


const Sitemap = (props) => {
    // console.log("menu list props = ",props)
    return (
        <Fragment>
            <div className={props.content.display_classname}>
                <div className="cloud-feature-inner">
                
                    <div className="list-widget">
                        {props.content.is_title_display &&<h3>{props.content.title}</h3>}
                        <ul>
                                {props.content.contents && props.content.contents.map((rows,key)=>{
                                    return (
                                        <li key={key}>
                                                <Link 
                                                    onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} 
                                                    to={rows.slug}>{rows.name}
                                                </Link>
                                                {rows.submenu.length?
                                                    <Fragment>
                                                        <ul className="sub-list">
                                                            {rows.submenu.map((subrow,subkey)=>{
                                                                return(
                                                                    <li key={subkey}>
                                                                        <Link 
                                                                            onClick={()=>props.setPathLayout({id:subrow.id,path:subrow.slug})} 
                                                                            to={subrow.slug}>{subrow.name}
                                                                        </Link>
                                                                    </li>
                                                                    
                                                                )
                                                            })}
                                                        </ul>
                                                    </Fragment>
                                                :''}
                                        </li>
                                    )
                                })}
                        </ul>
                    </div>
                    </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) =>({

})
const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})



export default connect(
    mapStateToProps,mapDispatchToProps
)(Sitemap);