import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import NewsEvent from './NewsEvent'
import News from './News'

class NewEventcontent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,

        }
    }


    render() {
        
    const location = this.props.history.location;
    const content = this.props.content;
        return (
            <Fragment>
                 {(content.type.toLowerCase() == 'news & event module' && location.pathname == '/') && <NewsEvent content={content} />}
                 {(content.type.toLowerCase() == 'news & event module' && location.pathname != '/') && <News content={content} />}
               
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


// const mapDispatchToProps = dispatch => ({
//     sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
// })
export default connect(
    mapStateToProps,null
)(NewEventcontent);