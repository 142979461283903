import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,sendContactus} from '../../actions/Utils'
import { connect } from 'react-redux';
import Contactform from './Contactform'
import InnerHTML  from 'dangerously-set-inner-html'
import { image_url } from '../../config/Api';

class Contactus extends React.Component {

    constructor(props) {
        super(props);
    }

    

  render() {
      
    const contents = this.props.content.contents;
    const content = this.props.content;
    
    var content_address = Array.isArray(contents)?contents.length?contents[0]:'':'';
    var content_form = Array.isArray(contents)?contents.length == 2?contents[1]:'':'';
    // var content_form = Array.isArray(contents)?contents[0].description.replace(/<\/?[^>]+(>|$)/g, ""):'';
    //var description = Array.isArray(contents)?contents[0].description.replace(/<\/?[^>]+(>|$)/g, ""):'';
// console.log(" content_address = ",content_address);
    return (
        <Fragment>
            <div className={content.display_classname}>
            <div className="container">
            <img src="img/contact/colosia.svg" alt="" />	
            <div className="row">
                <div className="col-md-6">
                <div className="contact-left">	
                    
                    {content_address.images && content_address.images.map((row,key)=>{
                       return(
                        <Fragment key={key}>
                            <img src={image_url + row.image} alt="Image" />
                        </Fragment>
                       )
                       
                    })}
                </div>

                    {content_address&&<InnerHTML html={content_address.description} />}
                </div>	
                <div className="col-md-6">
                    <Contactform content={content} content_form = {content_form}/>
                </div>
            </div>
            </div>
            
            </div>
        </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    sendContactus : (requestData)=>{dispatch(sendContactus(requestData))},
})

export default connect(null,mapDispatchToProps)(Contactus);