import '../App.css'
import React, { Component,Fragment } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer'
import Container from '../components/Container'
import { withRouter } from "react-router-dom";
import {getSetting,setPathLayout,sectionandContent} from "../actions/Utils"


import {connect} from 'react-redux' 



class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
        };
    }
    componentDidMount = () => {
        const {location} = this.props.history;
        var {pathname} = location

       
       if(!this.props.layout_id && pathname == '/') {
            this.props.getSetting().then((res) => {
                if(pathname == '/') {
                   this.setPathLayout({path:pathname,id:res.menu_id});
                   if(res.menu_id) {
                        this.getContentData(res.menu_id);
                   }
                  
                }
                else {

                }
              });
        }
        else if(!this.props.layout_id && pathname != '/') {
            this.getSetting();

            // let str = pathname.substr(0,1);
            // if(str == '/') {
            //     pathname = pathname.substring(1)
            // }
           
            var menuid = '';
           
            this.props.routemenu.map((menu,key)=>{
                if(menu.slug == pathname) {
                    menuid = menu.id;
                    return true;
                }
                menu.submenu.map((subval,subkey)=>{
                    if(subval.slug == pathname) {
                        menuid = subval.id;
                        return true;
                    }
                })
            })

            if(menuid) {
               
                this.setPathLayout({path:pathname,id:menuid});
            }
           
        }
     
    }

    setPathLayout = (parms) => {
       this.props.setPathLayout(parms);
    }
    getSetting = async () => {
       let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
            return {
                path: props.utils.path,
                header:props.header,
                container:props.container,
                footer:props.footer,
                layout_id:props.utils.layout_id
             };
      }
    getContentData = (menu_id) => {
      
        const requestData = {
            menu_id:menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {
        var {path} = this.state
        const {header_loading, footer_loading} = this.props.utils;
        let str = path.substr(0,1);
        if(str == '/') {
            path = path.substring(1)
        }
        return (
            <Fragment>
                {
                (header_loading && footer_loading)?
                    <Fragment>
                        <div id="loader-1">
                            <span></span><span></span><span></span><span></span><span></span>
                        </div>
                    </Fragment>:
                <Fragment>
                    <Header parent_page = {(path == '/' || path =='')?'default':path} />
                    <Container parent_page = {(path == '/' || path =='')?'default':path} />   
                    <Footer parent_page = {(path == '/' || path =='')?'default':path} />
                </Fragment>
                }
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    homelayout:state.homelayout,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));