import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import CertificationsCarousel from './CertificationsCarousel'
class Certification extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const slider = this.props.slider;
    const content = this.props.content;

    return (
        <div className="certification">
        <section>
            <div className="container">
                <div className="wrapper">
                    <div className="section-title-seven">
                        <h1>{content.short_description}</h1>
                        <p>
                            {content.description}
                        </p>
                    </div>
                    <div className="regular slider">
                        <CertificationsCarousel content={slider} image_url={image_url}/>
                    </div>
                </div>
            </div>
        </section>
    </div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(Certification);