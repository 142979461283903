import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import InnerHTML  from 'dangerously-set-inner-html'

class ProductAndServicesEnterprise extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab_list:[],
            prev_tab:'',
            current_tab:'',
            total_tab:0,
            current_tab_index:0,
        }
    }

    componentDidMount = () =>{
        const contents = this.props.content.contents;
        const tab = contents.map((rowtab,key)=>{
            return {
                id:rowtab.id,
                status:key == 0 ?true:false
            }
        })
        
        let [key, value] = Array.isArray(tab) && tab.length ?Object.entries(tab)[0]:Object.entries([{id:'',status:''}])[0];

        this.setState({tab_list:tab,current_tab:value.id,total_tab:tab.length});
       
        
        this.tabid = setInterval(() => {
            this.handleTabChange(this.state.current_tab);
        }, 3000);
    }

    

    handleTabChange = () => {
       
        const {tab_list,current_tab_index,total_tab} = this.state;
        let [key, value] = Array.isArray(tab_list) && tab_list.length ?Object.entries(tab_list)[current_tab_index]:Object.entries([{id:'',status:''}])[0];
        let current_tab_ind = total_tab-1== current_tab_index?0:current_tab_index+1;
        this.setState({current_tab:value.id,current_tab_index:current_tab_ind});
    }
    
    componentWillUnmount() {
        clearInterval(this.tabid);
    }

    handleStopSlide = () =>{
        
        clearInterval(this.tabid);
    }
    handleReopenSlide = () =>{
        this.tabid = setInterval(() => {
            this.handleTabChange(this.state.current_tab);
        }, 3000);
    }
    handleInitSlide = (id,index) =>{
        
        this.setState({current_tab:id,current_tab_index:index});
    }

  render() {
      
    const content = this.props.content;
    const contents = this.props.content.contents;
    const {current_tab} = this.state;
    return (
        <div className="what-we-do-bg productandServiceEnterprise">
        <section>
            <div className="container">
                <div className="wrapper">
                <div className="what-we-do">
                    <div className="section-title-seven">
                        <h1>{content.title}</h1>
                        {content.sec_description && <p>{content.sec_description} </p>}

                    </div>
                    <div className="product-service-slider">
	
						         
                    <div className="product-service-tab">
                        <div className="align-items-start">

                          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                          
                            {Array.isArray(contents) && contents.map((row,key)=>{
                                return (
                                    <Fragment key={key}>
                                            <button className= {`nav-link " ${current_tab == row.id? "active" : ""}`} id={'product-service-'+row.listcontent.id} data-bs-toggle="pill" data-bs-target={'#product-'+row.listcontent.id} type="button" role="tab" aria-controls={'product-'+row.listcontent.id} aria-selected="false" onClick={()=>this.handleInitSlide(row.id,key)}>
                                                <a href="#">{row.listcontent.title}</a>
                                            </button>
                                    </Fragment>
                                        
                                )
                            })}
                              
                            

                            </div>





                            <div className="tab-content" id="v-pills-tabContent" onMouseOver={()=>this.handleStopSlide()} onMouseLeave={()=>this.handleReopenSlide()}
                            onTouchMove={()=>this.handleStopSlide()}
                            onTouchCancel ={()=>this.handleReopenSlide()}
                            >
                              {Array.isArray(contents) && contents.map((row,key)=>{
                                return (
                                    <Fragment key={key}>
                                        <div className={`tab-pane fade ${row.id == current_tab?'show active':''}`} id={'product-'+row.listcontent.id} role="tabpanel" aria-labelledby={'product-'+row.listcontent.id}>
                                        {row.list && row.list.map((lrow,lkey)=>{
                                            return (
                                                <Fragment key={lkey}>
                                                    <div className="product-single-content">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <div className="product-icon">
                                                                    <img src={image_url+lrow.image} alt="image" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <h3>{lrow.title}</h3>  
                                                                <p>{lrow.description}</p>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </Fragment>
                                            )
                                        })}
                                       
                                       
                                    </div>

                                    </Fragment>
                                    
  
                                )
                            })}

                          </div>

                          <div className="clearfix"></div>

                      </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(ProductAndServicesEnterprise);