import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { image_url } from '../../config/Api';
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'

class BenefitBusiness  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {
      const is_loading = this.state.is_loading;
      const content = this.props.content.contents;
      
    //   console.log(" list content 002= ",content);
        return (
           <Fragment>
               {is_loading ?<Fragment>Loading...</Fragment>:<Fragment>
                    {content.map((rows,key)=>{
                       
                        return (
                            <div className="benifit-business" key={key}>
                            <section>
                                <div className="container">
                                    <div className="wrapper">
                                        <div className="section-title-seven">
                                                     {rows.listcontent.title &&<h1>{rows.listcontent.title}</h1>}
                                                    {rows.listcontent.description &&<p>
                                                    {rows.listcontent.description }
                                                    </p>}
                                        </div>
                                        <div className="benifit-business-list">
                                            <div className="row">

                                            {rows.listcontent.item_number && rows.list && rows.list.slice(0,parseInt(rows.listcontent.item_number)).map((rowList,lkey) =>{
                                                return (
                                                    <div className="col-md-4" key={rowList.id+key+lkey}>
                                                        <div className="benifit-business-list-single">
                                                            {rowList.image &&<img src={image_url+rowList.image} alt="Image"/>}
                                                            {rowList.title &&<h3>{rowList.title}</h3>}
                                                            {rowList.description &&<p>
                                                                {rowList.description}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            {!rows.listcontent.item_number && rows.list && rows.list.map((rowList,lkey) =>{
                                                return (
                                                    <div className="col-md-4" key={key+lkey}>
                                                        <div className="benifit-business-list-single">
                                                            {rowList.image &&<img src={image_url+rowList.image} alt="Image"/>}
                                                            {rowList.title &&<h3>{rowList.title}</h3>}
                                                            {rowList.description &&<p>
                                                                {rowList.description}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        )
                    })}
                
                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})
export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(BenefitBusiness));