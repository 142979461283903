const HeaderReducer = {
    topmenu:[],
    rightsite:[]
}

export default(state = HeaderReducer,action) =>{
    switch (action.type) {
        case 'INIT_HEADER':
            return {
                ...state,
                ...action.data
            }
        case 'INIT_TOPMENU':
            return {
                ...state,
                topmenu:action.data
            }
        case 'INIT_RIGHTSITE':
            return {
                ...state,
                rightsite:action.data
            }
        default:
            return state;
            
    }
}