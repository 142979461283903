import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { image_url } from '../../config/Api';
import { Link } from "react-router-dom";
import { setPath, setLayout, setPathLayout, detailsContent } from '../../actions/Utils'
import NewsdefaultImage from '../../img/news-default.jpeg';
class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id: '',
            is_loading: false,
        }
    }


    render() {
        const is_loading = this.state.is_loading;
        const content = this.props.content.contents;

        //   console.log(" list content CloudFeatureInner 001= ",content);
        return (
            <Fragment>
                {is_loading ? <Fragment>Loading...</Fragment> : <Fragment>
                    {content.news&&<Fragment>
                            <div className="container">
                                <section>
                                    <div className="news-event-main">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="achievement-content">
                                                    <div className="row">



                                                        {content.news && content.news.map((rowList, lkey) => {
                                                            let slug = rowList.category_id==2?`/event${rowList.slug}`:`/news${rowList.slug}`;
                                                            return (

                                                                <div className="col-md-4" key={lkey}>

                                                                    <div className="news-event">
                                                                        {rowList.image ? <img src={image_url + rowList.image} alt="Image" /> : <img src={NewsdefaultImage} alt="Image" />}
                                                                    </div>
                                                                    <div className="news-event-single">
                                                                        <h4>{rowList.category?rowList.category.name:'None'} <span> {rowList.publish_date_format}</span> </h4>
                                                                        {rowList.title ? <h3>
                                                                            <Link
                                                                                        to={{pathname:slug}}>
                                                                                        {rowList.title.length>46?rowList.title.substring(0,43)+'...':rowList.title}
                                                                                    </Link>
                                                                        </h3> : ''}

                                                                        {rowList.description ? <p>
                                                                            {rowList.short_description.length>125? rowList.short_description.substring(0,122)+'...':rowList.short_description}
                                                                        </p> : ''}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                </Fragment>}

                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id: state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    detailsContent: (requestData) => { dispatch(detailsContent(requestData)) },
})
export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(News));