import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import InnerHTML  from 'dangerously-set-inner-html'

class ProductAndServicesTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab_list:[],
            prev_tab:'',
            current_tab:'',
            total_tab:0,
            current_tab_index:0,
        }
        
    }

    componentDidMount = () =>{
        const contents = this.props.content.contents;
        const tab = contents.map((rowtab,key)=>{
            return {
                id:rowtab.id,
                status:key == 0 ?true:false
            }
        })
        
        let [key, value] = Array.isArray(tab) && tab.length ?Object.entries(tab)[0]:Object.entries([{id:'',status:''}])[0];

        this.setState({tab_list:tab,current_tab:value.id,total_tab:tab.length});
       
        
        this.tabid = setInterval(() => {
            this.handleTabChange(this.state.current_tab);
        }, 3000);
    }

    

    handleTabChange = () => {
       
        const {tab_list,current_tab_index,total_tab} = this.state;
        let [key, value] = Array.isArray(tab_list) && tab_list.length ?Object.entries(tab_list)[current_tab_index]:Object.entries([{id:'',status:''}])[0];
        let current_tab_ind = total_tab-1== current_tab_index?0:current_tab_index+1;
        this.setState({current_tab:value.id,current_tab_index:current_tab_ind});
    }
    
    componentWillUnmount() {
        clearInterval(this.tabid);
    }

    handleStopSlide = () =>{
        
        clearInterval(this.tabid);
        
    }
    handleReopenSlide = () =>{
        
        this.tabid = setInterval(() => {
            this.handleTabChange(this.state.current_tab);
        }, 3000);

    }
    handleInitSlide = (id,index) =>{
        
        this.setState({current_tab:id,current_tab_index:index});
    }

  render() {
      
    const content = this.props.content;
    const contents = this.props.content.contents;
    const {current_tab} = this.state;
    // console.log("services tab = ",this.state);
    // console.log("contents = ",contents);
    // console.log(" product and srvices tab = ",content);
    return (
        <div className="product-service">
        <section>
            <div className="container">
                <div className="wrapper">
                    <div className="section-title-six">
                        <h1>{content.title} </h1>
                    </div>
                    <div className="product-service-tab">
                        <div className="align-items-start">

                          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                            
                            {Array.isArray(contents) && contents.map((row,key)=>{
                                var description = row.description.replace(/<\/?[^>]+(>|$)/g, "");
                                return (
                                        <button key={key} className= {`nav-link " ${current_tab == row.id? "active" : ""}`} id={'product-service-'+row.id} data-bs-toggle="pill" data-bs-target={'#product-'+row.id} type="button" role="tab" aria-controls={'product-'+row.id} aria-selected="false" onClick={()=>this.handleInitSlide(row.id,key)}>
                                            <div className="tab-icon">
                                                {Array.isArray(row.images) && row.images.map((irow,ikey)=>{
                                                    return (
                                                        <Fragment key={ikey + irow.id}>
                                                                <img src={image_url+irow.image} alt=""/>
                                                        </Fragment>
                                                    )
                                                })}
                                                
                                            </div>
                                            <div className="product-tab-content">
                                                <h6>{row.short_description}</h6>
                                                <div className="tab-content-des">
                                                   <p>{description.substr(0,60)}</p>
                                                </div>
                                            </div>
                                            
                                        </button>
                                )
                            })}
                              


                            </div>





                            <div className="tab-content" id="v-pills-tabContent" onMouseOver={()=>this.handleStopSlide()} onMouseLeave={()=>this.handleReopenSlide()} 
                            onTouchMove={()=>this.handleStopSlide()}
                            onTouchCancel ={()=>this.handleReopenSlide()}
                            >
                                {Array.isArray(contents) && contents.map((row,key)=>{
                                    return (
                                        <Fragment key={key}>
                                            <div className={`tab-pane fade ${row.id == current_tab?'show active':''}`} id={'product-'+row.id} role="tabpanel" aria-labelledby={'product-'+row.id}>
                                            <h3>{row.short_description}</h3>
                                            <div className="tab-content-des">
                                                <InnerHTML html={row.description} />
                                            </div>
                                        
                                        </div>

                                        </Fragment>
                                        
    
                                    )
                                })}

                            </div>

                          <div className="clearfix"></div>

                      </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(ProductAndServicesTab);