const contenerReducer = {
    content_loading:true,
    search_content:{},
    search_key:'',
    content_details:{}
}

export default(state = contenerReducer,action) =>{
    switch (action.type) {
        // case 'INIT_PAGE_NAME':
        //     var page = {};
            
        //     action.data.map((val,key)=>{
        //          if(val.slug){
        //             let slugs= val.slug.replace(/\s+/g, '_');
        //              page['page_'+slugs] = {content:{},content_loaded:false};
        //          }
        //          if(val.slug == null) {
        //              page['page_default'] = {content:{},content_loaded:false};
        //          }

        //          var total_length = Object.keys(page).length;
        //          var objectname = Object.keys(page)[total_length-1];

        //          val.submenu.map((subval,subkey)=>{
        //             if(subval.slug){
        //                 let slugs= subval.slug.replace(/\s+/g, '_');
        //                 page[objectname+'_sub_'+slugs] = {content:{},content_loaded:false};
        //              }
        //              if(subval.slug == null){
        //                  page[objectname+'_sub_default'] = {content:{},content_loaded:false};
        //              }
        //          })
        //     }) 
        
        //     return {
        //         ...state,
        //         ...page
        //     }
        case 'INIT_PAGE_NAME':
            var page = {};
            
            action.data.map((val,key)=>{
                page['page_'+val.id] = {content:{},content_loaded:false};
                val.submenu.map((subval,subkey)=>{
                    page['page_'+subval.id] = {content:{},content_loaded:false};
                })
            }) 
        
            return {
                ...state,
                ...page
            }
        case 'SET_SEARCH_KEY':
          
            return {
                ...state,
                search_key:action.search,
            }
        case 'INIT_SEARCH_CONTENT':
            
            return {
                ...state,
                search_content:action.data,
            }
       
        case 'ADD_SEARCH_CONTENT':
           
            var content_data = action.data.data;
            var action_data = action.data;
            delete  action_data.data;
            console.log("action_data =",action_data);
            console.log("content_data =",content_data);
            console.log("previous data content_data =",state.search_content.data);
            return {
                ...state,
                search_content:{
                    ...action_data,
                    data:[
                        ...state.search_content.data,
                        ...content_data
                    ]
                }

            }
        case 'ADDED_CONTENT':
            var searchobj = 'page_'+action.id;
            var contentdata = state;
        //    console.log("contentdata=",contentdata);
        //    console.log("action.data=",action.data);
            Object.entries(state).map(layout => {
            //    console.log("layout =",layout);
                if(layout[0] === searchobj) {
                  
                    contentdata[searchobj] = {content:action.data,content_loaded:false}
                    // console.log(`contentdata[searchobj]`,contentdata[searchobj]);
                    // console.log("contentdata[page_32]",contentdata['page_32']);
                } 
            });

            return {
                ...state,
                ...contentdata,
                content_loading:false,
            }
        case 'CONTENT_LOAD_STATUS':
            var searchobj = 'page_'+action.id;
            var contentdata = state;
           
            Object.entries(state).map(layout => {
                if(layout[0] == searchobj) {
                    contentdata[searchobj] = {content:contentdata[searchobj].content,content_loaded:true}
                } 
            });

            return {
                ...state,
                ...contentdata,
                content_loading:false,
            }
        case 'SET_DETAILS_CONTENT':
            
            return {
                ...state,
                content_details:action.data,
            }
        default:
            return state;
            
    }
}