import React, {Fragment} from 'react';
import { connect } from "react-redux";
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {withRouter} from 'react-router-dom';
import $ from "jquery";

import {setPath,setLayout,setPathLayout,setSearchKey} from '../../actions/Utils'

const Header = (props) => {
  
    const topmenu = props.headers.topmenu;
    const rightsite = props.headers.rightsite;
    const setting = props.setting;
    const {location} = props.history;
    var {pathname} = location
    var vergamenu = '';

    

    function handleSearch(event) {
        if (event.keyCode == 13) {
          
            // console.log('event.charCode =',event.charCode)
            event.preventDefault();
            props.setSearchKey(event.target.value);
            props.history.push('/search?q='+event.target.value);
        }
    }
    function handleVergamenu(event) {
        if(vergamenu == '') {
            vergamenu = 'openmenu';
            $('.omenu').addClass('openmenu');
        }
        else {
            vergamenu = '';
            $('.omenu').removeClass('openmenu');
        }

        $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
       
    }

    return (
        
        <Fragment>
            
          <div className="header-top">
              <div className="container normal-header">
                  <div className="row">
                      <div className="col-md-2">
                          <div className="logo">
                              {/* <a href="#">
                                  <img src="img/logo.svg" alt="Logo" />
                              </a> */}
                          
                              {(setting && setting.logo) &&<Link onClick={()=>props.setPathLayout({id:setting.menu_id?setting.menu_id:'',path:'/'})} to='/'>
                                    <img src={setting.logo} alt="Logo" />
                            </Link>}
                          </div>
                      </div>
                      <div className="col-md-10">
                          <div className="main-menu main-menu-desktop">
                              <div className="menu-container">
                                <div className="menu">
                                <ul className="clearfix">
                                        {topmenu.length && topmenu.map((rows,key)=>{
                                            return (
                                                 <li key={key}>
                                                    <Link onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} to={rows.slug?rows.slug:''}>
                                                            {rows.name}
                                                    </Link>
                                                    
                                                    {(rows.submenu).length ?<ul>
                                                        <li>{rows.name}
                                                            {(rows.submenu).length?<Fragment>
                                                                <ul>
                                                                {rows.submenu && rows.submenu.map((subrows,skey)=>{
                                                                    return(
                                                                        <li key={key+skey}>
                                                                            
                                                                            {subrows.images &&<img src={image_url+subrows.images} alt=""/>}
                                                                            <Link onClick={()=>props.setPathLayout({id:subrows.id,path:subrows.slug})} to={subrows.slug?subrows.slug:''}>{subrows.name}
                                                                                <div className="arrow"></div>
                                                                            </Link>
                                                                            {subrows.title &&<p>{subrows.title}</p>}
                                                                        
                                                                        </li>
                                                                    )

                                                                })}
                                                                    
                                                                </ul>
                                                            </Fragment>:''}
                                                        </li>
                                                    
                                                    
                                                    </ul>:''}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                              </div>
                          </div>
                          <div className="vergamenu">
                              <div className={`toggle-menu`}>
                                  <nav role='navigation'>
                                    <div className="omenu" id="menuToggle" onClick={()=>handleVergamenu()}>
                                      <input type="checkbox" />
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <ul id="menu">
                                        <img src="/img/logo-colour.svg" alt="" />
                                        <form action="#" onSubmit={e => { e.preventDefault(); }}>
                                            <input type="text" placeholder="Search"  onKeyUp={(e) => handleSearch(e)} className="form-control" />
                                            <img src="/img/search.svg" />
                                        </form>
                                        <div className="main-menu mobile-main-menu">
                                              <div className="menu-container">
                                                <div className="menu">
                                                <ul className="clearfix">
                                        {topmenu.length && topmenu.map((rows,key)=>{
                                            return (
                                                 <li key={key}>
                                                  
                                                    <Link onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} to={rows.slug}>
                                                            {rows.name}
                                                    </Link>
                                                    
                                                    {(rows.submenu).length ?<ul>
                                                        <li>{rows.name}
                                                            {(rows.submenu).length?<Fragment>
                                                                <ul>
                                                                {rows.submenu && rows.submenu.map((subrows,skey)=>{
                                                                    return(
                                                                        <li key={key+skey}>
                                                                            
                                                                            {subrows.images &&<img src={image_url+subrows.images} alt=""/>}
                                                                            <Link onClick={()=>props.setPathLayout({id:subrows.id,path:subrows.slug})} to={subrows.slug}>{subrows.name}
                                                                                <div className="arrow"></div>
                                                                            </Link>
                                                                            {subrows.title &&<p>{subrows.title}</p>}
                                                                        
                                                                        </li>
                                                                    )

                                                                })}
                                                                    
                                                                </ul>
                                                            </Fragment>:''}
                                                        </li>
                                                    
                                                    
                                                    </ul>:''}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                                </div>
                                              </div>
                                          </div>
                                        <div className="find-us">
                                            
                                            {Array.isArray(rightsite) && rightsite.length?
                                                <Fragment>
                                                    {rightsite.map((rmenu,rkey) =>{
                                                        return(
                                                           
                                                                <Link onClick={()=>props.setPathLayout({id:rmenu.id,path:rmenu.slug})} to={rmenu.slug} key={rkey}>
                                                                        {rmenu.name}
                                                                </Link>
                                                           
                                                        )
                                                    })}
                                                </Fragment>
                                            :''}
                                            {setting?<div className="menu-social">
                                                {/* <Link to={'tel:'+setting.phone}>
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </Link> */}
                                                <a href={'tel:'+setting.phone}>
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </a>
                                                <a href={setting.fb_link} target="_blank">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                                {setting.twitter_link && <a href={setting.twitter_link} target="_blank">
                                
                                                    <i className="fab fa-twitter"></i>
                                                </a>}
                                                {setting.instagram_link &&<a href={setting.instagram_link} target="_blank">
                                                    <i className="fab fa-instagram-square"></i>
                                                </a>}
                                                {setting.linkedin && <a href={setting.linkedin} target="_blank">
                                                    
                                                    <i className="fab fa-linkedin"></i>
                                                </a>}
                                                {/* <Link to={setting.youtube_link} target="_blank">
                                                    <i className="fab fa-youtube"></i>
                                                </Link>
                                                <Link to={setting.instagram_link} target="_blank">
                                                    <i className="fab fa-instragram"></i>
                                                </Link> */}
                                            </div>:''}
                                        </div>
                                      </ul>
  
                                    </div>
                                  </nav>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>



              <div className="sticky-header">
                  <div className="container">
                       <div className="row">
                      <div className="col-md-2">
                          <div className="logo">
                              {/* <a href="#">
                                  <img src="img/logo.svg" alt="Logo" />
                              </a> */}
                          
                              {(setting && setting.logo) &&<Link onClick={()=>props.setPathLayout({id:setting.menu_id?setting.menu_id:'',path:'/'})} to='/'>
                                    <img src={setting.logo} alt="Logo" />
                            </Link>}
                          </div>
                      </div>
                      <div className="col-md-10">
                          <div className="main-menu main-menu-desktop">
                              <div className="menu-container">
                                <div className="menu">
                                <ul className="clearfix">
                                        {topmenu.length && topmenu.map((rows,key)=>{
                                            return (
                                                 <li key={key}>
                                                    <Link onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} to={rows.slug?rows.slug:''}>
                                                            {rows.name}
                                                    </Link>
                                                    
                                                    {(rows.submenu).length ?<ul>
                                                        <li>{rows.name}
                                                            {(rows.submenu).length?<Fragment>
                                                                <ul>
                                                                {rows.submenu && rows.submenu.map((subrows,skey)=>{
                                                                    return(
                                                                        <li key={key+skey}>
                                                                            
                                                                            {subrows.images &&<img src={image_url+subrows.images} alt=""/>}
                                                                            <Link onClick={()=>props.setPathLayout({id:subrows.id,path:subrows.slug})} to={subrows.slug?subrows.slug:''}>{subrows.name}
                                                                                <div className="arrow"></div>
                                                                            </Link>
                                                                            {subrows.title &&<p>{subrows.title}</p>}
                                                                        
                                                                        </li>
                                                                    )

                                                                })}
                                                                    
                                                                </ul>
                                                            </Fragment>:''}
                                                        </li>
                                                    </ul>:''}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                              </div>
                          </div>
                          <div className="vergamenu">
                              <div className={`toggle-menu`}>
                                  <nav role='navigation'>
                                    <div className="omenu" id="menuToggle" onClick={()=>handleVergamenu()}>
                                      <input type="checkbox" />
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <ul id="menu">
                                        <img src="/img/logo-colour.svg" alt="" />
                                        <form action="#" onSubmit={e => { e.preventDefault(); }}>
                                            <input type="text" placeholder="Search"  onKeyUp={(e) => handleSearch(e)} className="form-control" />
                                            <img src="/img/search.svg" />
                                        </form>
                                        <div className="main-menu mobile-main-menu">
                                              <div className="menu-container">
                                                <div className="menu">
                                                <ul className="clearfix">
                                        {topmenu.length && topmenu.map((rows,key)=>{
                                            return (
                                                 <li key={key}>
                                                  
                                                    <Link onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} to={rows.slug}>
                                                            {rows.name}
                                                    </Link>
                                                    
                                                    {(rows.submenu).length ?<ul>
                                                        <li>{rows.name}
                                                            {(rows.submenu).length?<Fragment>
                                                                <ul>
                                                                {rows.submenu && rows.submenu.map((subrows,skey)=>{
                                                                    return(
                                                                        <li key={key+skey}>
                                                                            
                                                                            {subrows.images &&<img src={image_url+subrows.images} alt=""/>}
                                                                            <Link onClick={()=>props.setPathLayout({id:subrows.id,path:subrows.slug})} to={subrows.slug}>{subrows.name}
                                                                                <div className="arrow"></div>
                                                                            </Link>
                                                                            {subrows.title &&<p>{subrows.title}</p>}
                                                                        
                                                                        </li>
                                                                    )

                                                                })}
                                                                    
                                                                </ul>
                                                            </Fragment>:''}
                                                        </li>
                                                    
                                                    
                                                    </ul>:''}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                                </div>
                                              </div>
                                          </div>
                                        <div className="find-us">
                                            
                                            {Array.isArray(rightsite) && rightsite.length?
                                                <Fragment>
                                                    {rightsite.map((rmenu,rkey) =>{
                                                        return(
                                                           
                                                                <Link onClick={()=>props.setPathLayout({id:rmenu.id,path:rmenu.slug})} to={rmenu.slug} key={rkey}>
                                                                        {rmenu.name}
                                                                </Link>
                                                           
                                                        )
                                                    })}
                                                </Fragment>
                                            :''}
                                            {setting?<div className="menu-social">
                                                {/* <Link to={'tel:'+setting.phone}>
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </Link> */}
                                                <a href={'tel:'+setting.phone}>
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </a>
                                                <a href={setting.fb_link} target="_blank">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                                {setting.twitter_link &&<a href={setting.twitter_link} target="_blank">
                                
                                                    <i className="fab fa-twitter"></i>
                                                </a>}
                                                {setting.instagram_link &&<a href={setting.instagram_link} target="_blank">
                                                    <i className="fab fa-instagram-square"></i>
                                                </a>}
                                                {setting.linkedin && <a href={setting.linkedin} target="_blank">
                                                    <i className="fab fa-linkedin"></i>
                                                </a>}
                                               
                                            </div>:''}
                                        </div>
                                      </ul>
  
                                    </div>
                                  </nav>
                              </div>
                          </div>
                      </div>
                  </div>                        
                  </div>
                  
              </div>

              
            </div>
            <div className="social-wrap">
                <div className="container">
                    {setting ?
                    <div className="social-media">
                        <div className="border-vertical"></div>
                        <div className="social-icon">
                            
                            {/* <Link to={'tel:'+setting.phone}>
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </Link> */}
                            <a href={'tel:'+setting.phone}>
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </a>
                            <a href={setting.fb_link} target="_blank">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            {setting.twitter_link && <a href={setting.twitter_link} target="_blank">
                                
                                <i className="fab fa-twitter"></i>
                            </a>}
                            {setting.instagram_link &&<a href={setting.instagram_link} target="_blank">
                                 <i className="fab fa-instagram-square"></i>
                            </a>}
                            {setting.linkedin && <a href={setting.linkedin} target="_blank">
                                
                                <i className="fab fa-linkedin"></i>
                            </a>}
                            
                          
                        </div>
                    </div>:''}
                </div>
            </div>
            
        </Fragment>
        
    );
}

const  mapStateToProps = (state) =>({
        headers: state.header,
        setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    setSearchKey : (requestData)=>{dispatch(setSearchKey(requestData))},
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));