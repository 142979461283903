import React, { Fragment } from 'react';

const ProductAndServicesList = (props) => {
	const content = props.content;
	
    return (
			<Fragment>
				{content ? content.map((lrow,lkey)=>{
					// console.log(" lkey = ",lkey)
					return(
						<Fragment key={lrow.id + lkey}>
							
								{lkey != '0' &&<div className="product-single-content">
									<div className="product-icon">
										{lrow.image &&<img className="pic" src={lrow.image} />}
									</div>
									{lrow.title &&<h3>{lrow.title}</h3>}
									{lrow.description &&<p>
										{lrow.description}
									</p>}
								</div>}
								
						</Fragment>
					)
				}):''}
			</Fragment>	
    )
}

	export default ProductAndServicesList;
