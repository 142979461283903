import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {setPath} from '../../actions/Utils'
import { Link } from "react-router-dom";


const Navbar = (props) => {
    // console.log("Navbar page = ",props)
    return (
        <Fragment>
            <div className={props.content.display_classname}>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                    <div className="container">
                        <a className="navbar-brand js-scroll-trigger" href="#page-top">Start Bootstrap</a>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto my-2 my-lg-0">
                                {props.content.contents && props.content.contents.map((rows,key)=>{
                                    return (
                                        <li className="nav-item" key={key}>
                                                <Link className="nav-link js-scroll-trigger" onClick={()=>props.setPath(rows.slug)} to={rows.slug}>{rows.name}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
          
        </Fragment>
    ); 
}

const mapStateToProps = (state) =>({

})
const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
})



export default connect(
    mapStateToProps,mapDispatchToProps
)(Navbar);