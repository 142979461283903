import '../App.css'
import React, { Component,Fragment, PureComponent } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer'
import Container from '../components/Container'
import { withRouter } from "react-router-dom";
import {getSetting,setPathLayout,setPath,getsearch} from "../actions/Utils"
import InnerHTML  from 'dangerously-set-inner-html'
import { Link } from "react-router-dom";
import {connect} from 'react-redux' 
import {image_url} from '../config/Api'



class Search extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            is_loading:false,
            limit:10,
            offset:0,
            load_more_show:false,
            content: {},
            loading: false,
            slug: '',
            key_search:'',

        };
    }
    componentDidMount = () => {
       
        const {location} = this.props.history;
        var {pathname} = location
        // let str = pathname.substr(0,1);
        // pathname = pathname.substring(1);
       
        this.setPathname(pathname);
        if(!Object.keys(this.props.utils.setting).length) {
            this.getSetting();
        }
        this.getSearchContent({initial:true});
        
    }

    getSearchContent = async (params) => {
      
        const {location} = this.props.history;
        const search = location.search.substring(3);
        let data = await this.props.getsearch({q:search,limit:this.state.limit,offset:this.state.offset,initial:params.initial,page:1}).then((res)=>{
            let offset = this.state.limit + this.state.offset;
            let load_more_show = res.length < this.state.limit ?false:true;
            this.setState({offset:offset,load_more_show:load_more_show,key_search:search,content: res.data})
        })
        
    }


    componentDidUpdate(prevProps){
      
        if ( prevProps.search_key != this.props.search_key) {
           
            this.getSearchContentReintital({initial:true}); 
        }
      }

      getSearchContentReintital = async (params) => {
      
        const {location} = this.props.history;
        const search = location.search.substring(3);
        let offset = 0;
        let data = await this.props.getsearch({q:search,limit:this.state.limit,offset:offset,initial:params.initial}).then((res)=>{
            let offset = this.state.limit;
            let load_more_show = res.length < this.state.limit ?false:true;
            this.setState({offset:offset,load_more_show:load_more_show})
        })
        
    }


    contentLoad = () => {
        const {location} = this.props.history;
        const search = location.search.substring(3);
        const content = this.props.search_content;
        const current_page = content.current_page;
        const to = content.to;
        const total = content.total;
        const requestData = {
          page: current_page + 1,
          q: search,
          initial:false,
          limit:this.state.limit
        }
        const req = this.props.getsearch(requestData).then((data) => {
    
    
          const getcontent = data;
          const newdata = data.data;
          delete data.data;
    
        //   var newstate = {
        //     ...this.state,
        //     content: {
        //       ...data,
        //       data: [
        //         ...this.state.content.data,
        //         ...newdata
        //       ]
        //     }
        //   }
        //   this.setState({
        //     ...newstate
        //   })
    
        });
      }



    setPathLayout = (parms) => {
       this.props.setPathLayout(parms);
    }

    setPathname = (parms) => {
       this.props.setPath(parms);
    }

    getSetting = async () => {
       let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
            return {
                path: props.utils.path,
                header:props.header,
                container:props.container,
                footer:props.footer,
                layout_id:props.utils.layout_id,
                search_key:props.contents.search_key,
             };
      }

    render() {
        const {path,load_more_show,key_search} = this.state
        const {header_loading, footer_loading} = this.props.utils;
        const content_data = this.props.search_content;
        const content = content_data.data;

        return (
            <Fragment>
                {
                (header_loading && footer_loading)?
                    <Fragment>
                        <div id="loader-1">
                            <span></span><span></span><span></span><span></span><span></span>
                        </div>
                    </Fragment>:
                <Fragment>
                    <Header parent_page = {'search'} />
                    
                    <div className="feature-header">
                    
                        <h1>Search</h1>
                        <img src="/img/feature/singlepage-header.png" />
                        
                    </div>
                    <div className="container">
                        <section>
                        
                            <div className="cloud-feature-inner">
                            <h1>Search results for <span>“{key_search}”</span></h1>
                            <h3 class="total-result">Total Search Result: {content_data?content_data.total:0}</h3>
                                <div className="achievement-content">

                                
                                    <div className="search_list">
                                        {content && content.map((row,key)=>{
                                            return(
                                                <Fragment key={key}>
                                                    {row.short_description?<div className="search_list_single">
                                                        {row.images && row.images.map((image,imagekey)=>{
                                                            if(imagekey == 0) {
                                                                return(
                                                                    <Fragment key={imagekey}>
                                                                        <img src={image_url+image.image}/>
                                                                    </Fragment>
                                                                )
                                                            }
                                                            else {
                                                                return false;
                                                            }
                                                            
                                                        })}
                                                       
                                                        
                                                        <h3>
                                                        <InnerHTML html={row.short_description} />
                                                        </h3>
                                                        {/* <p>
                                                           <InnerHTML html={row.description} />
                                                        </p> */}
                                                        {row.section.menu_id && <Link onClick={()=>this.props.setPathLayout(
                                                            {
                                                                id:row.section.menu_id,
                                                                path:row.section.menu_name?row.section.menu_name.slug:''})} to={row.section.menu_name?row.section.menu_name.slug:''}>
                                                        Read More
                                                        </Link>}
                                                        {row.section.menu_id != true&& <Link onClick={()=>this.props.setPathLayout(
                                                            {
                                                                id:32,
                                                                path:'/'})} to={'/'}>
                                                        Read More
                                                        </Link>}
                                                        
                                                    </div>:''}
                                                </Fragment>
                                            )
                                        })}
                                        
                                       
                                       

                                        {Object.keys(content_data).length ? <Fragment>
                  {content_data.to < content_data.total && <Fragment>
                    <div className="load_more">
                    <button className="btn-style-three" onClick={this.contentLoad}>Load More </button>
                    </div>
                  </Fragment>}
                </Fragment> : ''}

                                        {Object.keys(content_data).length ? <Fragment>{!content.length&&<h3>There aren't any matches for your search.</h3>}</Fragment>:''}
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer parent_page = {'search'} />
                </Fragment>
                }
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    homelayout:state.homelayout,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    search_key:state.contents.search_key,
    search_content:state.contents.search_content,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    setPath:(requestData) =>dispatch(setPath(requestData)),
    getsearch:(requestData) =>dispatch(getsearch(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Search));