// import axios from 'axios';
// import API from '../config/Api';
import qs from 'qs';
import axiosInstance,{version} from '../config/Api'



  export const setPath = (path) => ({
      type: 'SET_PATH',
      path
  });
  export const setLayout = (id) => ({
      type: 'SET_LAYOUT_ID',
      id
  });
  export const setPathLayout = (data) => ({
      type: 'SET_PATH_LAYOUT',
      data
  });

  export const initHeader = (data) => ({
      type: 'INIT_HEADER',
      data
  });
  export const setHeaderLoading = (status) => ({
      type: 'SET_HEADER_LOADING',
      status
  });
  export const setFooterLoading = (status) => ({
      type: 'SET_FOOTER_LOADING',
      status
  });

 


  export const getHeaderSection = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/header',qs.stringify()).then(res => {
        if(Object.keys(res.data).length){
          dispatch(initHeader(res.data));
          dispatch(setHeaderLoading(false));
        }
        else {
          dispatch(setHeaderLoading(false));
        }
        return res.data;
      });
    }
  }

  export const initTopMenuHeader = (data) => ({
    type: 'INIT_TOPMENU',
    data
  });

  export const getTopMenu = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/topmenu',qs.stringify()).then(res => {
        if(Object.keys(res.data).length) {
          dispatch(initTopMenuHeader(res.data));
          dispatch(setHeaderLoading(false));
        }
        else {
          dispatch(setHeaderLoading(false));
        }
        return res.data;
      });
    }
  }
  export const initRightSiteMenuHeader = (data) => ({
    type: 'INIT_RIGHTSITE',
    data
  });

  export const getRightSiteMenu = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/rightmenu',qs.stringify()).then(res => {
        if(Object.keys(res.data).length) {
          dispatch(initRightSiteMenuHeader(res.data));
        }
       
        return res.data;
      });
    }
  }

  export const initFooter = (data) => ({
      type: 'INIT_FOOTER',
      data
  });


   export const  sendContactus = (parms) => {
 
    return (dispatch) => {
      return axiosInstance.post(version+'sendContactus',qs.stringify(parms)).then(res => {
      
        return res;
      });
    }

  }
  export const getaQuote = (parms) => {
   
  
    return (dispatch) => {
      return axiosInstance.post(version+'sendContactus',qs.stringify(parms)).then(res => {
      
        return res.data;
      });
    }
  }
  export const getFooterSection = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/footer',qs.stringify()).then(res => {
        // console.log("getFooterSection section = ",res.data);
        if(Object.keys(res.data).length){
          dispatch(initFooter(res.data));
          dispatch(setFooterLoading(false));
        }
        else {
          dispatch(setFooterLoading(false));
        }
        return res.data;
      });
    }
  }

  
  
  export const contentAdded = (data,id) => ({
    type: 'ADDED_CONTENT',
    data:data,
    id:id
  });

  export const contentLoadStatus = (id) => ({
    type: 'CONTENT_LOAD_STATUS',
    id:id
  });


  export const sectionandContent = (parms) => {

    // var string = '';
    // var length = Object.entries(parms).length;
    // Object.entries(parms).forEach((ele,key) => {
    //   string += ele[0]+'='+ele[1]
    //   if(length-1 !== key) {
    //     string += '&'
    //   }
    // });

    return (dispatch) => {
      // return axiosInstance.get(version+'get/contents?'+string,qs.stringify()).then(res => {
      return axiosInstance.get(version+'get/contents',{params:parms }).then(res => {
        // console.log("sectionandContent section = ",res.data);
        if(Object.keys(res.data).length) {
          dispatch(contentAdded(res.data,parms.menu_id));
        }
        if(Object.keys(res.data).length === 0) {
          dispatch(contentLoadStatus(parms.menu_id));
        }
        return res;
      });
    }
  }


  export const initRoute = (data) => ({
      type: 'INIT_ROUTES',
      data
  });

  export const initpagename = (data) => ({
      type: 'INIT_PAGE_NAME',
      data
  });


  export const getRoutes = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/routes',qs.stringify()).then(res => {
        if((res.data).length){
          dispatch(initRoute(res.data));
          // console.log(" res.data = ",res.data);
          dispatch(initpagename(res.data));
        }
        return res.data;
      });
    }
  }
  export const setSetting = (data) => ({
      type: 'SET_SETTING',
      data
  });
  export const setSearchKey = (search) => ({
      type: 'SET_SEARCH_KEY',
      search
  });


  export const getSetting = () => {
    
    return (dispatch) => {
      return axiosInstance.get(version+'get/setting').then(res => {
        if(Object.keys(res.data).length){
          dispatch(setSetting(res.data));
        }
        return res.data;
      });
    }
  }

  export const initSearchContent = (data) => ({
    type: 'INIT_SEARCH_CONTENT',
    data
  });
  export const addSearchContent = (data) => ({
    type: 'ADD_SEARCH_CONTENT',
    data
  });

  export const getsearch = (parms) => {
    
    // var string = '';
    // var length = Object.entries(parms).length;
    // Object.entries(parms).forEach((ele,key) => {
    //   string += ele[0]+'='+ele[1]
    //   if(length-1 !== key) {
    //     string += '&'
    //   }
    // });
   
    return (dispatch) => {
      return axiosInstance.get(version+'get/contentsearch',{params:parms}).then(res => {
       
          if(parms.initial === true){
         
            dispatch(initSearchContent(res.data));
          }
          else {
           
            dispatch(addSearchContent(res.data));
          }
           
        
        return res.data;
      });
    }
  }


  export const detailsContent = (data) => ({
      type: 'SET_DETAILS_CONTENT',
      data
  });

  export const getContentDetails = (parms) => {
    
    var string = '';
    var length = Object.entries(parms).length;
    Object.entries(parms).forEach((ele,key) => {
      string += ele[1]
      if(length-1 !== key) {
        string += '&'
      }
    });

    return (dispatch) => {
      return axiosInstance.get(version+'get/content-details?'+string).then(res => {
        
        dispatch(detailsContent(res.data));
        return res.data;
      });
    }
  }
  export const getNewsContentDetails = (parms) => {
    
   

    return (dispatch) => {
      return axiosInstance.get(version+'get/news-details',{params:parms}).then(res => {
        
        dispatch(detailsContent(res.data));
        return res.data;
      });
    }
  }