import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import ProductAndServicesList from './ProductAndServicesList'

class ProductAndServices extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const content = this.props.content;
    const contents = this.props.content.contents;
    const listcontent = contents[0].listcontent;
    // console.log(" product and services  = ",this.props.content);
    var navlist = [];
     contents.map((cdata,ckey)=>{
       if(Array.isArray(cdata.list) && cdata.list.length) {
            navlist[ckey] = cdata.list[0];
       }
        
    })
    
    // console.log(" navlist =",navlist);
    return (
        <div className="what-we-do-bg">
  		<section>
  			<div className="container">
  				<div className="wrapper">
	  				<div className="what-we-do">
		  				<div className="section-title-seven">
							{listcontent.title ?<h1>{listcontent.title}</h1>:''}
							{listcontent.description ?<p>{listcontent.description}</p>:''}
						</div>
						<div className="product-service-slider">
							<div id="desSlideshow1" className="desSlideshow">
						         <div className="switchBigPic">

                                    {contents && contents.map((row,key)=>{
                                       
                                        return(
                                           
                                            <Fragment key={key}>
                                                    <ProductAndServicesList content = {row.list} image_url={image_url}/>
                                              
                                            </Fragment>
                                        )
                                    })}
						            
						         </div>
						         <ul className="nav">
                                     {navlist.map((nrow,nkey)=>{
                                         return (
                                             <Fragment key={nkey}>
                                                 <li><a href="#">{nrow.title}</a></li>
                                             </Fragment>
                                         )
                                     })}
						          
						         </ul>
						      </div>
						</div>
		  			</div>
		  		</div>
	  		</div>
  		</section>
</div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(ProductAndServices);