import React, { Fragment } from 'react';
import { image_url } from '../../config/Api'
import { Link } from "react-router-dom";
import { setPath, setLayout, setPathLayout } from '../../actions/Utils'
import { connect } from 'react-redux';
class WhatWeDoList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            contentList:[],
            list_headding:''
        }
    }

    componentDidMount = () =>{
        var listofcontent = this.props.contentList;
        const content = this.props.content;

        var list_headding = '';
        var Nlength = listofcontent.length;
        if (Nlength) {
            list_headding = listofcontent[0];
            this.setState({
                contentList:listofcontent.slice(1,Nlength),
                list_headding:list_headding
            })
        }
        
    }
    render() {

        const contentList = this.state.contentList;
        const list_headding = this.state.list_headding;
       

        return (


            <div className="col-md-4">
                <div className="what-we-do-single">

                    {list_headding.image && <div className="what-we-do-banner">
                        <img src={image_url + list_headding.image} alt="" />
                    </div>}
                    {list_headding.title ? <h3>{list_headding.title}</h3> : ''}

                    <ul>

                        {contentList ? <Fragment>{contentList.map((row, key) => {
                            return (
                                <li key={key}>
                                    {row.title}
                                </li>
                            )
                        })}</Fragment> : ''}

                    </ul>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(null, mapDispatchToProps)(WhatWeDoList);