
import React, { Fragment } from 'react';
import {connect} from 'react-redux' 
import Topmenu from '../menu/Topmenu'
import Navbar from '../menu/Navbar'
import Htmlcontent from '../Htmlcontent'
import MenuList from '../menu/MenuList'
import Getquoteform from '../contactus/Getquoteform'

const Footer = (props) => {
    // console.log(' Hello footers data = ',props.footers);
    return (
        <Fragment>
            <div className={'footer-section pageof_footer_'+ props.parent_page} id="footer" >
            <footer>
            <div className="container">
                <div className="row">
                    {Object.keys(props.footers).length && Object.entries(props.footers).map((rows,key) => {
                        return (
                            <Fragment key={key}> 
                                {(rows[1].type === 'menu' && rows[1].menu_typename === 'top') && <Topmenu content = {rows[1]} section_name={rows[1].name} />}
                                {(rows[1].type === 'menu' && rows[1].menu_typename === 'navbar') && <Navbar content = {rows[1]} section_name={rows[1].name} />}
                                {(rows[1].type === 'menu' && (rows[1].menu_typename === 'company' || rows[1].menu_typename === 'important link' || rows[1].menu_typename === 'business')) && <MenuList content = {rows[1]} section_name={rows[1].name} />}
                                {rows[1].type === 'html' && <Htmlcontent content = {rows[1]} section_name={rows[1].name}/>}
                                {/* {rows[1].type === 'list' && <ListView content = {rows[1]} section_name={rows[1].name}/>} */}
                            </Fragment>
                        )
                    })}
                </div>
                </div>
            </footer>
            </div>
            <Getquoteform />
        </Fragment>
    );
}

function mapStateToPropos(state) {
    return {
        footers:state.footer
    }
}
export default connect(mapStateToPropos)(Footer);


