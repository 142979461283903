import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { image_url } from '../../config/Api';
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,detailsContent} from '../../actions/Utils'

class ValueAddedServices  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {
      const is_loading = this.state.is_loading;
      const content = this.props.content.contents;
      
    //   console.log(" list content ValueAddedServices 002= ",content);
        return (
           <Fragment>
               {is_loading ?<Fragment>Loading...</Fragment>:<Fragment>
                    {content.map((rows,key)=>{
                        return (
                            <div className="value-added-services" key={rows.id + key}>
                                <section>
                                    <div className="container">
                                        <div className="wrapper">
                                            <div className="section-title-seven">
                                                {rows.listcontent.title ?<h1>{rows.listcontent.title}</h1>:''}
                                                {rows.listcontent.description ?<p>
                                                {rows.listcontent.description }
                                                </p>:''}
                                            </div>
                                            <div className="value-added-list">
                                                <div className="row">

                                                    {rows.listcontent.item_number && rows.list && rows.list.slice(0,parseInt(rows.listcontent.item_number)).map((rowList,lkey) =>{
                                                            return (
                                                                <div className="col-md-3" key={rowList.id+lkey}>
                                                                <div className="added-list-single">
                                                                    {rowList.image ?<img src={image_url+rowList.image} alt="Image"/>:''}
                                                                    <div className="list-single-content">
                                                                        {rowList.title ?<h3>{rowList.title}</h3>:''}
                                                                        {rowList.description ?<p>
                                                                            {rowList.description}
                                                                        </p>:''}
                                                                        {rowList.is_show_more_button ?<Fragment>
                                                                           

                                                                            {rowList.more_button_name ?<Fragment>
                                                                            {rowList.internal_link?
                                                                                <Link 
                                                                                    onClick={()=>this.props.setPathLayout({id:rowList.internal_link,path:rowList.menu?rowList.menu.slug:''})}
                                                                                    to={rowList.menu?rowList.menu.slug:''}
                                                                                    className="btn-style-three">
                                                                                    
                                                                                        {rowList.more_button_name}
                                                                                   
                                                                                    
                                                                                </Link>
                                                                                :rowList.external_link? <Link to={{ pathname:rowList.external_link}} target="_blank"
                                                                                className="btn-style-three">
                                                                                 
                                                                                    {rowList.more_button_name}
                                                                               
                                                                            </Link>: 

                                                                            <Link
                                                                            onClick={()=>this.props.detailsContent(
                                                                                {
                                                                                    content:rowList,
                                                                                    content_type:'list',
                                                                                    section_title:rowList.title
                                                                                }
                                                                            )}
                                                                            to={`/content/details?type=list&id=${rowList.id}`}
                                                                            className="btn-style-three">
                                                                                
                                                                                     {rowList.more_button_name}
                                                                               
                                                                           
                                                                            </Link>

                                                                            }
                                                                            </Fragment>:''}

                                                                        </Fragment>:''}
                                                                        
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            )
                                                    })}
                                                    {!rows.listcontent.item_number && rows.list && rows.list.map((rowList,lkey) =>{
                                                        return (

                                                            <div className="col-md-3" key={rowList.id+lkey}>
                                                            <div className="added-list-single">
                                                                {rowList.image ?<img src={image_url+rowList.image} alt="Image"/>:''}
                                                                <div className="list-single-content">
                                                                    {rowList.title ?<h3>{rowList.title}</h3>:''}
                                                                    {rowList.description ?<p>
                                                                        {rowList.description}
                                                                    </p>:''}
                                                                    {rowList.is_show_more_button ?<Fragment>
                                                                        

                                                                        {rowList.more_button_name ?<Fragment>
                                                                            {rowList.internal_link?
                                                                                <Link 
                                                                                    onClick={()=>setPathLayout({id:rowList.internal_link,path:rowList.menu?rowList.menu.slug:''})}
                                                                                    to={rowList.menu?rowList.menu.slug:''}
                                                                                    className="btn-style-three">
                                                                                  
                                                                                        {rowList.more_button_name}
                                                                                    
                                                                                    
                                                                                </Link>
                                                                                :rowList.external_link? <Link to={{ pathname:rowList.external_link}} target="_blank"
                                                                                className="btn-style-three">
                                                                               
                                                                                    {rowList.more_button_name}
                                                                               
                                                                                 
                                                                            </Link>: 

                                                                            <Link
                                                                            onClick={()=>detailsContent(
                                                                                {
                                                                                    content:rowList,
                                                                                    content_type:'list',
                                                                                    section_title:rowList.title
                                                                                }
                                                                            )}
                                                                            to={`/content/details?type=list&id=${rowList.id}`}
                                                                            className="btn-style-three">
                                                                              
                                                                                    {rowList.more_button_name}
                                                                             
                                                                           
                                                                            </Link>

                                                                            }
                                                                            </Fragment>:''}

                                                                    </Fragment>:''}
                                                                    
                                                                </div>
                                                            </div>
                                                            </div>
                                                        )
                                                    })}
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    })}
                
                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})
export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(ValueAddedServices));