import React,{Fragment} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {isMobile} from 'react-device-detect';


export default function CertificationsCarousel(props) {
  var settings = {
    dots: false,
    infinite: isMobile?true:props.content.length >3 ?true:false,
    slidesToShow: isMobile?1:3,
    slidesToScroll: 1,
    autoplay: isMobile?true:props.content.length >3 ?true:false,
    autoplaySpeed: 2500
  };
  
  return (
    <Slider {...settings}>
                  {props.content ? props.content.map((srow,skey)=>{
                    return(
                    <Fragment key={srow.id + skey}>
                            <div className="certification-banner">
                                <img src={props.image_url+srow.long_image} alt="Image" />
                            </div>
                    </Fragment>
                    )
                }):''}
    </Slider>
  );
}