import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import NewsEvent from './NewsEvent'

class HtmlSlidercontent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,

        }
    }


    render() {
    const contents = this.props.content.contents;
    const content = this.props.content;

        return (
            <Fragment>
                {content.type == 'html and slider' && <NewsEvent content={content} />}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


// const mapDispatchToProps = dispatch => ({
//     sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
// })
export default connect(
    mapStateToProps,null
)(HtmlSlidercontent);