import React, { Fragment } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
class HeaderMainSlider extends React.Component {
  render() {
    const slider = this.props.slider;
    return (
        <div className="main-slider">
            <Carousel 
                autoPlay={true} 
                showThumbs={false}
                infiniteLoop
                showArrows={false}
                selectedItem={false}
                showStatus={false}
            >
                 {slider.map((row,key)=>{
                
                return(
                    <Fragment key={key}>

                        <div className="slider-banner-laptop">
                            <img src={image_url+row.thumb_image} />
                        </div>
                        <div className="slider-banner-desktop">
                             <img src={image_url+row.long_image} />
                        </div>
                        <div className="container">
                            <div className="slider-caption">
                                {row.title &&<h1 className="slider-title">{row.title}</h1>}
                                {row.short_descripton &&<p className="slider-description">{row.short_descripton}</p>}
                                
                                    {row.internal_link &&<Link onClick={()=>this.props.setPathLayout({id:row.internal_link,path:row.menu?row.menu.slug:''})} to={row.menu?row.menu.slug:''}
                                    className="btn-style-three">
                                   
                                        Get Started
                                   
                                    </Link>}
                                    
                                    {(row.external_link && !row.internal_link) &&<Link target={"_blank"}  to={row.external_link}
                                    className="btn-style-three">
                                   
                                        Get Started
                                    
                                    </Link>}
                                    
                                
                            </div>
                        </div>
                     
                       
                    </Fragment>
                
                )
                    
            
            })}
        
        
            </Carousel>
            </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(HeaderMainSlider);