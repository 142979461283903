import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { image_url } from '../../config/Api';
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,detailsContent} from '../../actions/Utils'

class Vas  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {
      const is_loading = this.state.is_loading;
      const content = this.props.content.contents;
      
    //   console.log(" list content CloudFeatureInner 001= ",content);
        return (
           <Fragment>
               {is_loading ?<Fragment>Loading...</Fragment>:<Fragment>
                    {content.map((rows,key)=>{
                        return (
                            <div className="container">
                                <section>
                                    <div className="cloud-feature-inner">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="achievement-content">
                                                    <div className="row">



                                                        {rows.listcontent.item_number && rows.list && rows.list.slice(0,parseInt(rows.listcontent.item_number)).map((rowList,lkey) =>{
                                                            
                                                            return (
                                                                
                                                                <div className="col-md-4" key={key+lkey}>
                                                                
                                                                    <div className="vas-top">
                                                                        {rowList.image ?<img src={image_url+rowList.image} alt="Image"/>:''}
                                                                    </div>
                                                                    <div className="vas-single">
                                                                        {rowList.title ?<h3>
                                                                            {rowList.internal_link?
                                                                                <Link 
                                                                                    onClick={()=>this.props.setPathLayout({id:rowList.internal_link,path:rowList.menu?rowList.menu.slug:''})}
                                                                                    to={rowList.menu?rowList.menu.slug:''}>
                                                                                    {rowList.title}
                                                                                </Link>
                                                                                :rowList.external_link? <Link to={{pathname:rowList.external_link}} target="_blank">
                                                                                {rowList.title}
                                                                            </Link>: 

                                                                            <Link
                                                                            onClick={()=>this.props.detailsContent(
                                                                                {
                                                                                    content:rowList,
                                                                                    content_type:'list',
                                                                                    section_title:rowList.title
                                                                                }
                                                                            )}
                                                                            to={`/content/details?type=list&id=${rowList.id}`}>
                                                                            {rowList.title}
                                                                            </Link>

                                                                            }
                                                                            </h3>:''}
                                                                        
                                                                        {rowList.description ?<p>
                                                                            {rowList.description}
                                                                        </p>:''}
                                                                    </div>
                                                                </div>
                                                            )
                                                    })}
                                                    {!rows.listcontent.item_number && rows.list && rows.list.map((rowList,lkey) =>{
                                                        
                                                        return (

                                                            <div className="col-md-4" key={key+lkey}>
                                                            
                                                                <div className="vas-top">
                                                                    {rowList.image ?<img src={image_url+rowList.image} alt="Image"/>:''}
                                                                </div>
                                                                <div className="vas-single">
                                                                {rowList.title ?<h3>
                                                                            {rowList.internal_link?
                                                                                <Link 
                                                                                    onClick={()=>this.props.setPathLayout({id:rowList.internal_link,path:rowList.menu?rowList.menu.slug:''})}
                                                                                    to={rowList.menu?rowList.menu.slug:''}>
                                                                                    {rowList.title}
                                                                                </Link>
                                                                                :rowList.external_link? <Link to={{pathname:rowList.external_link}}>
                                                                                {rowList.title}
                                                                            </Link>: 

                                                                            <Link
                                                                            onClick={()=>this.props.detailsContent(
                                                                                {
                                                                                    content:rowList,
                                                                                    content_type:'list',
                                                                                    section_title:rowList.title
                                                                                }
                                                                            )}
                                                                            to={`/content/details?type=list&id=${rowList.id}`}>
                                                                            {rowList.title}
                                                                            </Link>

                                                                            }
                                                                            </h3>:''}
                                                                    
                                                                    {rowList.description ?<p>
                                                                        {rowList.description}
                                                                    </p>:''}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    })}
                
                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    detailsContent : (requestData)=>{dispatch(detailsContent(requestData))},
})
export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(Vas));