import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { image_url } from '../../config/Api';
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'

class WhoWeServe  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {
      const is_loading = this.state.is_loading;
      const content = this.props.content.contents;
      
    //   console.log(" list content 002= ",content);
        return (
           <Fragment>
               {is_loading ?<Fragment>Loading...</Fragment>:<Fragment>
                    {content.map((rows,key)=>{
                        return (
                            <div className="who-we-serve" key={rows.id + key}>
                            <section>
                                <div className="container">
                                    <div className="wrapper">
                                        <div className="row">
                                            <div className="col-md-6">
                                                  <div className="who-we-gallery">
                                                     
                                                      {rows.images && rows.images.map((irow,ikey)=>{
                                                          return (
                                                              <Fragment key={ikey}>
                                                                    {irow.image &&<img src={image_url+irow.image} alt=""/>}
                                                              </Fragment>
                                                          )
                                                      })}
                                                     
                                                  </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="section-title-four">
                                                    {rows.listcontent.title &&<h1>{rows.listcontent.title}</h1>}
                                                    {rows.listcontent.description &&<p>
                                                    {rows.listcontent.description }
                                                    </p>}
                                                </div>
                                                  <div className="serve-item">
                                                      <div className="row">
                                                        

                                                        {rows.listcontent.item_number && rows.list && rows.list.slice(0,parseInt(rows.listcontent.item_number)).map((rowList,lkey) =>{
                                                            return (
                                                                <div className="col-md-6" key={rows.id + key+lkey}>
                                                                {rowList.image &&<img src={image_url+rowList.image} alt="Image"/>}
                                                                {rowList.title &&<h3>{rowList.title}</h3>}
                                                            </div>
                                                            )
                                                        })}
                                                        {!rows.listcontent.item_number && rows.list && rows.list.map((rowList,lkey) =>{
                                                            return (
                                                                <div className="col-md-6" key={rows.id + key+lkey}>
                                                                {rowList.image &&<img src={image_url+rowList.image} alt="Image"/>}
                                                                {rowList.title &&<h3>{rowList.title}</h3>}
                                                            </div>
                                                            )
                                                        })}
                                                      </div>

                                                      
                                                      {rows.listcontent.is_show_more_button ?<div className="more-feature">
                                                        
                                                        <Link onClick={()=>this.props.setPathLayout({id:rows.listcontent.menu_id,path:rows.listcontent.menu?rows.listcontent.menu.slug:''})} to={rows.listcontent.menu?rows.listcontent.menu.slug:''}
                                                        className="btn-style-two">
                                                                  
                                                                        {rows.listcontent.more_button_name}
                                                                    
                                                                    </Link>
                                                            
                                                          
                                                        </div>:''}
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        )
                    })}
                
                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})
export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(WhoWeServe));