import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import WhatWeDo from "./WhatWeDo"
import CloudFeature from "./CloudFeature"
import WhoWeServe from "./WhoWeServe"
import DataCenterFeatures from "./DataCenterFeatures"
import BenefitBusiness from "./BenefitBusiness"
import ValueAddedServices from "./ValueAddedServices"
import CloudFeatureInner from "./CloudFeatureInner"
import CloudFeatureEnterprise from "./CloudFeatureEnterprise"
import Vas from "./Vas"
import Package from "./Package"
import ProductAndServices from "./ProductAndServices"
import ProductAndServicesEnterprise from './ProductAndServicesEnterprise'

class Listcontent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {
        const is_loading = this.state.is_loading;
      const content = this.props.content;
      const typename = content.typename?content.typename.toLowerCase():'';
    //   console.log(" list content 001 lenght= ",content.contents.length);
        return (
            <Fragment>
                {((typename == 'what-we-do-list' && content.contents.length)) ? <WhatWeDo  content = {content}  />:''}
                {((typename == 'cloud-feature-list' && content.contents.length)) ? <CloudFeature  content = {content}  />:''}
                {((typename == 'who-we-serve' && content.contents.length)) ? <WhoWeServe  content = {content}  />:''}
                {(typename == 'data-center-features' && content.contents.length) ? <DataCenterFeatures  content = {content}  />:''}
                {(typename == 'benefit-business' && content.contents.length) ? <BenefitBusiness  content = {content}  />:''}
                {(typename == 'value-added-services' && content.contents.length) ? <ValueAddedServices  content = {content}  />:''}
                {(typename == 'cloud-feature-inner' && content.contents.length) ? <CloudFeatureInner content = {content}  />:''}
                {(typename == 'vas-list' && content.contents.length) ? <Vas content = {content}  />:''}
                {(typename == 'enterprise-cloud-feature' && content.contents.length) ?<CloudFeatureEnterprise content = {content}  />:''}
                {(typename == 'packages-list' && content.contents.length) ? <Package content = {content}  />:''}
                {/* {(typename == 'product and services' && content.contents.length) && <ProductAndServices content = {content}  />} */}
                {(typename == 'product and services enterprise' && content.contents.length) ? <ProductAndServicesEnterprise content = {content}  />:''}
                
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


// const mapDispatchToProps = dispatch => ({
//     sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
// })
export default withRouter(connect(
    mapStateToProps,null
)(Listcontent));