import React,{Fragment} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Moment from 'react-moment';
import 'moment-timezone';
import {isMobile} from 'react-device-detect';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,detailsContent} from '../../actions/Utils'
import NewsdefaultImage from '../../img/news-default.jpeg';

const EventCarousel = (props) => {
  var settings = {
    dots: false,
    infinite: isMobile?true:props.content.length >3 ?true:false,
    slidesToShow: isMobile?1:3,
    slidesToScroll: 1,
    autoplay: isMobile?true:props.content.length >3 ?true:false,
    autoplaySpeed: 2500
  };
  
  return (
    <Slider {...settings}>
                  {props.content ? props.content.map((srow,skey)=>{
                      let slug = srow.category_id==2?`/event${srow.slug}`:`/news${srow.slug}`;
                    return(
                    <Fragment key={srow.id + skey}>
                        
                        <div className="slick-banner">
                            {srow.image?<img src={props.image_url+srow.image} alt="Image" />:<img src={NewsdefaultImage} alt="Image" />}
                            
                        </div>
                        <div className="slick-banner-content">
                         

                            {srow.title ?<h3>
                                    {srow.title.length>46? <Link to={{pathname:slug}}>{srow.title.substring(0,43)+'...'}</Link>:<Link to={{pathname:slug}}>{srow.title}</Link>}
                            </h3>:''}
                            <ul className="navbar nav">
                                <li>
                                   {/* {srow.posted_date &&<Moment date={srow.posted_date} format="MMMM DD, YYYY"  />} */}
                                   {srow.publish_date_format}
                                </li>
                                <li>
                                    {srow.read_time}
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                    )
                }):''}
    </Slider>
  );
}

const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    detailsContent : (requestData)=>{dispatch(detailsContent(requestData))},
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EventCarousel));

