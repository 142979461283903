import axios from 'axios';

var token = localStorage.getItem("token")?'Token '+localStorage.getItem("token"):'';
// export default axios.create({
//   // baseURL: `http://jsonplaceholder.typicode.com/`
//   baseURL: `http://127.0.0.1:8000/`,
//   headers: {'Authorization': token}
// });

const axiosInstance = axios.create();
// axiosInstance.defaults.baseURL = 'http://192.168.10.116/mir-admin/api/';
// axiosInstance.defaults.baseURL = 'http://localhost/mir-admin/api/';
axiosInstance.defaults.baseURL = 'https://mircloudbd.com/miradmin/api/';
// axiosInstance.defaults.headers.common['Authorization'] = token;
// axiosInstance.defaults.headers.common['User-ID'] = '';

export const version = 'v1/';
export const imagePath = 'v1/';

// export const image_url = 'http://192.168.10.116/mir-admin/';
// export const image_url = 'http://localhost/mir-admin/';
export const image_url = 'https://mircloudbd.com/miradmin/';
// export const base_path = 'http://192.168.10.116/mir-admin/sites/';
export const base_path = 'https://mircloudbd.com';
// export const base_path = 'http://localhost:3000/';

export const google_recaptcha_site_key = '6Le2E5gaAAAAADPCWtOtzWkaBwQ1jAlPtjP7XRuK';

export default axiosInstance;

 