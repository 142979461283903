import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
class PackageList extends React.Component {
  render() {

    var contentList = this.props.contentList;
    var list_headding = '';

    if(contentList.length) {
        list_headding = contentList.shift();
    }
    const toplist = contentList.filter(list => list.list_position == 1 );
    const bottomlist = contentList.filter(list => list.list_position == 2 );

    // console.log(" toplist = ",toplist);
    // console.log(" bottomlist = ",bottomlist);

    return (
       

                    <div className="col-md-4">
                    <div className="packages-list-single">
                        
                        {list_headding.image ?<img src={image_url+list_headding.image} alt="" />:''
                        }
                        {list_headding.title?<h3>{list_headding.title}</h3>:''}
                            <ul className="list-cercle-style">
                                {toplist?<Fragment>{toplist.map((row,key)=>{
                                    return (
                                            <Fragment key={row.id+key}>
                                                <li>{row.title}<span>{row.description}</span></li>
                                            </Fragment>
                                    )
                                })}</Fragment>:''}
                            </ul>
                            <ul className="list-check-style">
                                {bottomlist?<Fragment>{bottomlist.map((row,key)=>{
                                    return (
                                            <Fragment key={row.id+key}>
                                                <li>{row.title}<span>{row.description}</span></li>
                                            </Fragment>
                                    )
                                })}</Fragment>:''}
                            </ul>
                           

                       
                    </div>
                </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(PackageList);