import '../App.css'
import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import Htmlcontent from './Htmlcontent'
// import ListView from './ListView'
import Topmenu from './menu/Topmenu'
import Navbar from './menu/Navbar'
import Sitemap from './menu/Sitemap'
import { withRouter } from "react-router-dom";
import {sectionandContent,setPathLayout} from '../actions/Utils'
import Slidercontent from './slider/Slidercontent'
import NewEventcontent from './news/NewEventcontent'
import Listcontent from './list/Listcontent'
import HtmlSlidercontent from './htmlslider/HtmlSlidercontent'
import ProductAndServicesTab from './list/ProductAndServicesTab'
import Contactus from './contactus/Contactus'


class Container  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:true
        }
    }

    componentDidMount = () =>{

        const content = this.props.contents;
        const layout_id = this.props.layout_id;
        const layoutname = 'page_'+layout_id;

        const {location} = this.props.history;
        
        var {pathname} = location
       
        if(pathname === '/') {
            this.setState({is_loading:false})
        }
       
        Object.keys(content).length && Object.entries(content).map((rows,key) => {
            if(rows[0] == layoutname && rows[1].content_loaded === false){
                this.getContentData();
            }
            else if(rows[0] == layoutname && rows[1].content_loaded === true) {
               this.setState({is_loading:false})
            }
            
        })
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        
    }

    

    static getDerivedStateFromProps(props,state) {
       
        if(props.layout_id) {
            return {
                layout_id:props.layout_id
             };
        }
        return null;
        

    }


    componentDidUpdate(prevProps){
        // console.log("prevProps = ",prevProps);
        // console.log("this.props.layout_id = ",this.props.layout_id);
        
        if ( (prevProps.layout_id != this.props.layout_id) && this.props.layout_id != '') {
            this.getContentData(); 
            // console.log("container con if condition")
        }
        if ( prevProps.layout_id == this.props.layout_id) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }

        if(this.props.history.action == 'POP'){
            
            var pathname = this.props.history.location.pathname;

            var menuid = '';
           
            this.props.routemenu.map((menu,key)=>{
                if(menu.slug == pathname) {
                    menuid = menu.id;
                    return true;
                }
                menu.submenu.map((subval,subkey)=>{
                    if(subval.slug == pathname) {
                        menuid = subval.id;
                        return true;
                    }
                })
            })

            var pathname = this.props.history.location.pathname;
            if(pathname === '/') {
                this.setPathLayout({path:pathname,id:this.props.setting.menu_id});
            }
            else {
                this.setPathLayout({path:pathname,id:menuid});
            }
            

           
        }
        // else if((prevProps.layout_id == this.props.layout_id) && this.props.layout_id != ''){
        //     let content = this.props.contents;
        //     let pagename = 'page_'+this.props.layout_id;
        //     let content_loaded = content[pagename].content_loaded;
        //     if(content_loaded === false) {
        //         this.getContentData(); 
        //         console.log("container con else if condition")
        //     }
           
        // }
        // else if(this.props.content_loading === false) {
        //     this.setState({is_loading:false})
        // }
       
      }
    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
     }
   

    getContentData = () => {
      
        const requestData = {
            menu_id:this.props.layout_id,
        }
        this.props.sectionandContent(requestData).then( ({data,status}) => {
            // console.log("response data = ",data)
            // console.log("response data  status = ",status)
            if(status == 200) {
                this.setState({is_loading:false})
            }
           
        })
    }
    
    render() {
    const content = this.props.contents;
    const {layout_id} = this.props;
    const layoutname = 'page_'+layout_id;
    const {is_loading} = this.state;
       
        // console.log(" container page Object.keys(content).length= ",this.state)
    // console.log("Hello Mobarak 001");
        return (
            <div className={'container-section pageof_cotainer_'+ this.props.parent_page} id="contain-body" >
               {is_loading ?<Fragment>
                  
                    <div id="loader-content">
                        <span></span><span></span><span></span><span></span><span></span>
                    </div>
               </Fragment>:<Fragment>
                {Object.keys(content).length ? Object.entries(content).map((rows,key) => {
                   
                     return (
                         <Fragment key={key}>

                             {rows[0] === layoutname ?<Fragment>
                                
                                 { Object.keys(rows[1].content).length ?Object.entries(rows[1].content).map((sectionrow,seckey) => {
                                        let sectiontype = sectionrow[1].type?sectionrow[1].type.toLowerCase():'';
                                        return (
                                            <Fragment key={key+seckey}> 
                                                {(sectiontype === 'menu' && sectionrow[1].menu_typename === 'top') ? <Topmenu content = {sectionrow[1]} section_name={sectionrow[1].name} />:''}
                                                {(sectiontype === 'menu' && sectionrow[1].menu_typename === 'navbar') ? <Navbar content = {sectionrow[1]} section_name={sectionrow[1].name} />:''}
                                                {(sectiontype === 'menu' && sectionrow[1].menu_typename === 'sitemap') ? <Sitemap content = {sectionrow[1]} section_name={sectionrow[1].name} />:''}
                                                {sectiontype === 'html' ? <Htmlcontent content = {sectionrow[1]} section_name={sectionrow[1].name}/>:''}
                                                {/* {sectiontype == 'list' && <ListView content = {sectionrow[1]} section_name={sectionrow[1].name}/>} */}
                                                {sectiontype === 'slider' ? <Slidercontent content = {sectionrow[1]} section_name={sectionrow[1].name}/>:''}
                                                {sectiontype === 'list' ? <Listcontent content = {sectionrow[1]} section_name={sectionrow[1].name} sectionContent={rows}/>:''}
                                                {sectiontype === 'html and slider' ? <HtmlSlidercontent content = {sectionrow[1]} section_name={sectionrow[1].name} sectionContent={rows}/>:''}
                                                {sectiontype === 'news & event module' ? <NewEventcontent content = {sectionrow[1]} section_name={sectionrow[1].name} sectionContent={rows} history={this.props.history}/>:''}
                                                {sectiontype === 'tab contents' ? <ProductAndServicesTab content = {sectionrow[1]} section_name={sectionrow[1].name} sectionContent={rows}/>:''}
                                                {sectiontype === 'contact us form' ? <Contactus content = {sectionrow[1]} section_name={sectionrow[1].name} sectionContent={rows}/>:''}
                                            </Fragment>
                                        )
                                }):''}

                                 {/* {sectionrow[1].type == 'list' && <Listcontent content = {rows}/>} */}
                            </Fragment>:''}
                        </Fragment>
                     )
               
                }):''}
                </Fragment>}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    contents: state.contents,
    content_loading: state.contents.content_loading,
    layout_id:state.utils.layout_id,
    path:state.utils.path,
    header:state.header,
    setting:state.utils.setting,
    routemenu:state.utils.routemenu,
})


const mapDispatchToProps = dispatch => ({
    sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
})
export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(Container));