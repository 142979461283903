const utilsReducer = {
       path:'/',
       layout_id:'',
       routemenu:[],
       setting:{},
        header_loading:true,
        footer_loading:true,
    }

export default(state = utilsReducer,action) =>{
    switch (action.type) {
        case 'SET_PATH':
            return {
                ...state,
                path: action.path
            } 
        case 'SET_LAYOUT_ID':
            return {
                ...state,
                layout_id: action.id
            } 
        case 'SET_PATH_LAYOUT':
            
            return {
                ...state,
                path: action.data.path,
                layout_id: action.data.id,
            } 
        case 'INIT_ROUTES':
            return {
                ...state,
                routemenu: action.data
            }
        case 'SET_HEADER_LOADING':
            return {
                ...state,
                header_loading: action.status
            }
        case 'SET_FOOTER_LOADING':
            return {
                ...state,
                footer_loading: action.status
            }
        case 'SET_SETTING':
            return {
                ...state,
                setting: action.data
            }
        default:
            return state;
            
    }
}