const homeLayoutReducer = {
    header:true,
    header_grid:1,
    footer:true,
    body:true,
    body_grid:2,
    footer_grid:1
}

export default(state = homeLayoutReducer,action) =>{
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                loginuser: {...action.user}
            }
        case 'SET_LOGEDUSER':
          
            return {
                ...state,
                loginuser: {...action.user}
            }
        case 'SET_LOGOUT':
        
            return {
                ...state,
                loginuser: {},
                isLogin:false,
            }
        case 'SET_IS_LOGIN':
            return {
                ...state,
                isLogin:action.status
            }
        default:
            return homeLayoutReducer;
            
    }
}