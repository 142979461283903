import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import WhatWeDoList from './WhatWeDoList'
class WhatWeDo extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const content = this.props.content;
    const contents = this.props.content.contents;
    const listcontent = contents[0].listcontent;
   
    return (
                <div className="what-we-do-bg">
                        <section>
                            <div className="container">
                                <div className="wrapper">
                                    <div className="what-we-do">
                                        <div className="section-title-one">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h1>{listcontent.title}</h1>
                                                </div>
                                                
                                                <div className="col-md-7">
                                                {listcontent.description &&<p>
                                                        {listcontent.description}
                                                    </p>}
                                                {listcontent.is_show_more_button ?
                                                    
                                                    <Link onClick={()=>this.props.setPathLayout({id:listcontent.menu_id,path:listcontent.menu?listcontent.menu.slug:''})} to={listcontent.menu?listcontent.menu.slug:''}
                                                    className="btn-style-one">
                                                   
                                                        {listcontent.more_button_name}
                                                    
                                                    
                                                    </Link>
                                                :''}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="what-we-do-list">
                                            <div className="row">

                                        {contents.map((rows,key) => {
                                            return (
                                                <Fragment key={key}>
                                                        {rows.list.length ?<WhatWeDoList contentList = {rows.list} content = {rows}  />:''}
                                                </Fragment>
                                            )
                                        })}
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(WhatWeDo);