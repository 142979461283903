import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { Link } from "react-router-dom";


const MenuList = (props) => {
    // console.log("menu list props = ",props)
    return (
        <Fragment>
            <div className={props.content.display_classname}>
            <div className="list-widget">
                {props.content.is_title_display &&<h3>{props.content.title}</h3>}
                <ul>
                        {props.content.contents && props.content.contents.map((rows,key)=>{
                            return (
                                <li key={key}>
                                        {rows.external_link &&<Link
                                            to={{pathname:rows.external_link?rows.external_link:rows.slug}}
                                            target={rows.external_link?'__blank':''}
                                            
                                            >
                                                {rows.name}
                                        </Link>}
                                        {!rows.external_link &&<Link 
                                            onClick={()=>props.setPathLayout({id:rows.id,path:rows.slug})} 
                                            to={{pathname:rows.external_link?rows.external_link:rows.slug}}
                                            target={rows.external_link?'__blank':''}
                                            >
                                                {rows.name}
                                        </Link>}
                                </li>
                            )
                        })}
                </ul>
            </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) =>({

})
const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})



export default connect(
    mapStateToProps,mapDispatchToProps
)(MenuList);