import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import EventCarousel from './EventCarousel'
import Htmlcontent from '../Htmlcontent'
import InnerHTML  from 'dangerously-set-inner-html'
class NewsEvent extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const content = this.props.content;
    const contents = content.contents;
    // console.log("content=",content);
    // console.log("contents=",contents);
    return (
            <div className="news-events">
            <section>
                <div className="container">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="section-title-two">
                                    {content && content.title ?<Fragment>
                                       <h1>{content.title}</h1> 
                                        </Fragment>:''}

                                    {content && content.sec_description ?<Fragment><p>{content.sec_description}</p></Fragment>:''}
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="events-btn">
                                    {content.section_explore?
                                    <Link 
                                        onClick={()=>this.props.setPathLayout({id:content.section_explore.id,path:content.section_explore.slug})}
                                        to={content.section_explore?content.section_explore.slug:''}
                                        className="btn-style-two">
                                           
                                            Explore
                                           
                                      
                                    </Link>
                                     
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                {contents.event&&<Fragment>
                                    <div className="main-events">
                                    <h4>
                                    {contents.event.title.length>73? contents.event.title.substring(0,70)+'...':contents.event.title}
                                    </h4>
                                    <Link className="btn-style-one" to={`/event${contents.event.slug}`}>View Details</Link>
                                </div></Fragment>}
                            </div>
                            <div className="col-md-9">
                                <section className="regular slider">
                                {contents.news ?  <EventCarousel content={contents.news} image_url = {image_url}/>:''}
                                   
                              </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>    

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(NewsEvent);