import React from 'react';
import InnerHTML  from 'dangerously-set-inner-html'
function Htmlcontent(props) {
    // console.log('html content =',props)
    return (
        <div className={props.content.display_classname}>
                {(props.content.is_title_display == 1 && props.content.title) && <div className="title">{props.content.title}</div>}
              {props.content.contents.length ?<InnerHTML html={props.content.contents[0]['description']} />:''}
                
        </div>
        
    );
}

export default Htmlcontent;