import React, { Fragment } from 'react';
import {image_url} from '../../config/Api'
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout} from '../../actions/Utils'
import { connect } from 'react-redux';
import InnerHTML  from 'dangerously-set-inner-html'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {isMobile} from 'react-device-detect';

class SoftwareService extends React.Component {

    constructor(props) {
        super(props);
    }
  render() {
      
    const sliderdata = this.props.slider;
    const content = this.props.content;
    const sectionInfo = this.props.sectionInfo;

var settings = {
    dots: false,
    infinite: isMobile?true:sliderdata.length >3 ?true:false,
    slidesToShow: isMobile?1:3,
    slidesToScroll: 1,
    autoplay: isMobile?true:sliderdata.length >3 ?true:false,
    autoplaySpeed: 2500
  };

    return (
        <div className="software-service">
		<section>
			<div className="container">
				<div className="wrapper">
					<div className="section-title-six">
						<h1>{sectionInfo.title}</h1>
					</div>

					<div className="regular slider">

                        <Slider {...settings}>
                            {Array.isArray(sliderdata) && sliderdata.map((row,key)=>{
                                    return (
                                        <Fragment key={key}>
                                            
                                                <div key={key}>
                                                    <div className="service-single-content">
                                                        <img src={image_url + row.long_image} />
                                                        <h3>{row.title}</h3>
                                                            <p>
                                                                {row.short_descripton}
                                                            </p>
                                                    </div>
                                                </div>
                                        </Fragment>
                                    )
                                })}
                        </Slider>

					</div>
					<div className="service-btn">
                        {
                            content.menu?
                              
                                 <Link onClick={()=>this.props.setPathLayout({id:content.menu.id,path:content.menu.slug})} to={content.menu?content.menu.slug:''}
                                 className="btn-style-two">
                                 
                                    Explore
                                 
                                </Link> 
                                :''
                        }
						
					</div>
				</div>
			</div>
		</section>
</div>

    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
})

export default connect(null,mapDispatchToProps)(SoftwareService);