import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import HeaderMainSlider from './HeaderMainSlider'
import AnimationUpDown from './AnimationUpDown'
// import ItemsCarousel from './ItemsCarousel'
import Certification from './Certification';
import SoftwareService from './SoftwareService';
import Testmonial from './Testmonial';

class Slidercontent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout_id:'',
            is_loading:false,
        }
    }


    render() {

    const content = this.props.content.contents;
   
    const {is_loading} = this.state;
    //   console.log("Slider content page = ",this.props.content)
    //   console.log("Slider content content = ",content)
        return (
           <Fragment>
               {is_loading ?<Fragment>Loading...</Fragment>:<Fragment>
                {content.map((rows,key) => {
                    //  console.log('rows.typename = ',rows.typename,"Slider content content = ",rows.slider)
                     return (
                         <Fragment key={key}>
                                {/* {(rows.typename == 'carousel slider' && (rows.slider).length) && <CarouselSlider slider = {rows.slider}  />} */}
                                {((rows.typename == 'header main slider' && (rows.slider).length)) ? <HeaderMainSlider slider = {rows.slider}  />:''}
                                {((rows.typename == 'animation up and down slider' && (rows.slider).length)) ? <AnimationUpDown slider = {rows.slider} sectionInfo={this.props.content} content={rows}/>:''}
                                {((rows.typename == 'certification' && (rows.slider).length)) ? <Certification slider = {rows.slider} sectionInfo={this.props.content} content={rows}/>:''}
                                {((rows.typename == 'software service' && (rows.slider).length)) ? <SoftwareService slider = {rows.slider} sectionInfo={this.props.content} content={rows}/>:''}
                                {((rows.typename == 'testmonial' && (rows.slider).length)) ? <Testmonial slider = {rows.slider} sectionInfo={this.props.content} content={rows}/>:''}
                        </Fragment>
                     )
                })}
                </Fragment>}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


// const mapDispatchToProps = dispatch => ({
//     sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
// })
export default withRouter(connect(
    mapStateToProps,null
)(Slidercontent));