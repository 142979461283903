import React, { Fragment } from 'react';
import { image_url } from '../../config/Api'
import { Link } from "react-router-dom";
import { setPath, setLayout, setPathLayout } from '../../actions/Utils'
import { connect } from 'react-redux';
class AnimationUpDown extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    // $("[data-autoscroll]").autoscroll();
    // console.log(" AnimationUpDown js");
    // setTimeout(() => {
    //   window.requestAutoscroll();
    // }, 3000);

  }


  render() {
    const sliders = this.props.slider;
    const sectionInfo = this.props.sectionInfo;
    const content = this.props.content;
    // console.log("AnimationUpDown 001 dddd", sliders)
    var numOfChunk = Math.round(sliders.length / 3) + 1;

    var slider = [];

    while (sliders.length) {
      const chunk = sliders.splice(0, numOfChunk);
      slider.push(chunk);
    }
    return (

      <div className="we-work-with">
        <section>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-md-5">
                  <div className="section-title-five">
                    {sectionInfo.is_title_display ? <h1>{sectionInfo.title}</h1> : ''}
                    {content.description ? <p>
                      {content.description}
                    </p> : ''}
                    {content.menu ?

                      <Link onClick={() => this.props.setPathLayout(
                        { id: content.menu.menu_id, path: content.menu ? content.menu.slug : '' })} to={content.menu ? content.menu.slug : ''}
                        className="btn-style-one">

                        Get Started


                      </Link>

                      : ''}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">



                    {slider && slider.map((row, key) => {
                      {
                        var rowdata = [];
                        var rowLength = row.length;
                        var b = 0;
                        while (b < 200) {
                          for (var i = 0; i < rowLength; i++) {
                            rowdata.push(row[i]);
                          }
                          b++;
                        }
                      }
                      return (

                        <div className="col-md-4" key={key}>
                          <ul className="data-list">
                            <marquee scrolldelay="300" behavior="scroll" scrollamount="15" direction={key % 2 == 0 ? 'up' : 'down'} height="100%">

                              {rowdata.length && rowdata.map((item, ikey) => {
                                return (
                                  <li key={ikey}>
                                    <img src={image_url + item.long_image} />
                                  </li>
                                )
                              })}

                            </marquee>
                          </ul>
                        </div>
                      )

                    })}



                  </div>
                </div>

              </div>
            </div>
            
          </div>
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})
// export default AnimationUpDown;
export default connect(null, mapDispatchToProps)(AnimationUpDown);