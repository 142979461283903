import React,{Fragment} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Moment from 'react-moment';
import 'moment-timezone';
import {isMobile} from 'react-device-detect';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {setPath,setLayout,setPathLayout,detailsContent} from '../../actions/Utils'

const EventCarousel = (props) => {
  var settings = {
    dots: false,
    infinite: isMobile?true:props.content.length >3 ?true:false,
    slidesToShow: isMobile?1:3,
    slidesToScroll: 1,
    autoplay: isMobile?true:props.content.length >3 ?true:false,
    autoplaySpeed: 2500
  };
  
  return (
    <Slider {...settings}>
                  {props.content ? props.content.map((srow,skey)=>{
                    return(
                    <Fragment key={srow.id + skey}>
                        
                        <div className="slick-banner">
                            <img src={props.image_url+srow.long_image} alt="Image" />
                            
                        </div>
                        <div className="slick-banner-content">
                         

                            {srow.title ?<h3>
                                {srow.internal_link?
                                    <Link 
                                        onClick={()=>props.setPathLayout({id:srow.internal_link,path:srow.menu?srow.menu.slug:''})}
                                        to={srow.menu?srow.menu.slug:''}>
                                        {srow.title}
                                    </Link>
                                    :srow.external_link? <Link to={{pathname:srow.external_link}} target="_blank">
                                    {srow.title}
                                </Link>:
                                <Link 
                                    onClick={()=>props.detailsContent(
                                        {
                                            content:srow,
                                            content_type:'slider',
                                            section_title:srow.title
                                        }
                                    )}
                                    to={`/content/details?type=slider&id=${srow.id}`}>
                                            
                                    {srow.title}
                                </Link>
                                
                                }
                            </h3>:''}
                            <ul className="navbar nav">
                                <li>
                                    {/* {srow.posted_date} */}
                                   {srow.posted_date &&<Moment date={srow.posted_date} format="MMMM DD, YYYY"  />}
                                </li>
                                <li>
                                    {srow.read_time}
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                    )
                }):''}
    </Slider>
  );
}

const mapStateToProps = (state) => ({
    layout_id:state.utils.layout_id,

})


const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    detailsContent : (requestData)=>{dispatch(detailsContent(requestData))},
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EventCarousel));

